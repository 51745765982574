import { Pipe, PipeTransform } from '@angular/core';
import { BoardService } from '../services/board.service';

@Pipe({
  name: 'board'
})
export class BoardPipe implements PipeTransform {

  constructor(private boardService: BoardService) {}
  transform(boardId: string): Promise<string> {
    return this.boardService.getBoard(boardId).then((board) => {
      return board.name;
    });
  }
}
