import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {}

  close(val) {
    this.closeModal.emit(val);
  }
}
