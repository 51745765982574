<div class="container mx-auto bg-white shadow rounded">
  <div class="xl:w-full border-b border-gray-300 py-5">
    <div class="flex items-center w-11/12 mx-auto">
      <div class="container mx-auto">
        <div class="mx-auto xl:w-full">
          <p class="text-lg text-gray-800 font-bold">Widget</p>
          <p class="text-sm text-gray-500 pt-1">
            Include your Board into your website
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12">
    <div class="flex flex-col mb-8">
      <div>
        <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">Widget Code</p>
        <p class="text-sm text-gray-500 dark:text-gray-400">
          Copy the following code into your current website. Place this code at
          the bottom of the body section 
        </p>
      </div>
      <div>
        <div class="flex flex-col relative mb-5 mt-6">
          <!-- Code block starts -->

          <textarea
            class="w-full shadow-lg text-sm text-gray-400"
            cols="8"
            rows="4"
            disabled
            >{{ text }}</textarea
          >
          <button
            (click)="copyCode()"
            class="mx-2 my-2 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-xs"
          >
            Copy Code
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
