import { Pipe, PipeTransform } from '@angular/core';
import { RoadmapService } from '../services/roadmap.service';

@Pipe({
  name: 'roadmap',
})
export class RoadmapPipe implements PipeTransform {
  constructor(private roadmapService: RoadmapService) {}
  transform(roadmapId: string): Promise<string> {
    return this.roadmapService.getRoadmap(roadmapId).then((roadmap) => {
      return roadmap.name;
    });
  }
}
