<!-- component -->
<style>
  .chat-services {
    transition: 0.5s;
    max-height: 0;
  }
  .chat-services.expand {
    max-height: 600px;
  }
  .message {
    max-width: 250px;
  }

  .chat-modal {
    transition: 0.5s;
    opacity: 0;
    transform: translateX(500px);
  }

  .chat-modal.show {
    opacity: 1;
    transform: translateX(0);
  }

  @keyframes animateModal {
    from {
      transform: translateX(200px) opacity(0);
    }
    to {
      transform: translateX(0) opacity(1);
    }
  }
</style>

<div class="z-50 fixed bottom-0 right-0 flex flex-col items-end ml-6 w-full">
  <div
    class="bg-white z-50  chat-modal mr-5 flex flex-col mb-5 shadow-lg sm:w-1/2 md:w-1/3 lg:w-1/4"
  >
    <!-- close button -->

    <!-- admin profile -->
    <div class="flex justify-end items-center text-fb-red p-2 px-3 mr-5 w-full">
      <div
        class="close-chat bg-red-500 hover:bg-red-600 text-white mb-1 w-7 h-7 flex justify-center items-center rounded-full self-end cursor-pointer"
      >
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fill-rule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </div>
    </div>
    <!-- chats -->
    <div class="flex flex-col px-2 chat-services overflow-auto">
      <iframe class="h-screen" [src]="urlString"></iframe>
    </div>
    <!-- send message -->
    <a
      href="https://featurebear.com/"
      target="_blank"
      class="flex group justify-center items-center p-2 relative bg-white cursor-pointer"
    >
      <p class="group-hover:text-red-400 text-gray-300 text-sm mr-4">
        Powered by
      </p>
      <img src="https://featurebear.com/assets/fba-logo.png" class="mr-2 h-4 w-auto" />
      <img src="https://featurebear.com/assets/fba-text.png" class="h-3 w-auto" />
    </a>
  </div>
  <div
    class="show-chat mx-10 mb-6 mt-4 bg-fb-red rounded-full p-2 text-fb-red hover:text-red-300 flex justify-center items-center cursor-pointer"
  >
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      class="bi bi-chat-text-fill"
      fill="currentColor"
      stroke-width="1"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
      />
    </svg>
  </div>
</div>

<link
  href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
  rel="stylesheet"
/>
