import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css'],
})
export class AddProjectComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  projectForm: FormGroup;
  logo: string = '';
  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private userService: UserService
  ) {
    this.createForm();
  }
  createForm() {
    this.projectForm = this.fb.group(
      {
        name: ['', Validators.required],
        description: [''],
      },
      { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {}

  async addProject() {
    console.log(this.projectForm);

    if (!this.projectForm.valid) return;
    let currentUser: User = await this.userService.getUser();

    const project: Project = {
      name: this.projectForm.controls['name'].value,
      description: this.projectForm.controls['description'].value,
      boards: [],
      owner: currentUser.id,
      settings: { linkBoards: false, logo: this.logo },
      changelog: [],
      roadmaps:[]
    };
    const projectId = await this.projectService.createProject(project);
    currentUser.projects.push(projectId);
    await this.userService.setUser(currentUser);
    this.close(projectId);
  }

  async upload(event) {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    // this.ref = this.afStorage.ref(randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    //this.task = this.ref.put(event.target.files[0]);
    const picture = await this.projectService.uploadBoardPicture(
      randomId,
      event.target.files[0]
    );
    this.logo = await picture.ref.getDownloadURL();
  }

  close(projectId) {
    this.closeModal.emit(projectId);
  }
}
