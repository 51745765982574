import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ProjectComponent } from './pages/project/project.component';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AddProjectComponent } from './components/add-project/add-project.component';
import { AddBoardComponent } from './components/add-board/add-board.component';
import { AddPostComponent } from './components/add-post/add-post.component';
import { BoardComponent } from './pages/board/board.component';
import { BoardListComponent } from './pages/board-list/board-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { TagInputModule } from 'ngx-chips';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { ProjectService } from './services/project.service';
import { BoardService } from './services/board.service';
import { PostService } from './services/post.service';
import { TagService } from './services/tag.service';
import { CommentService } from './services/comment.service';
import { ChangelogService } from './services/changlog.service';
import { RoadmapService } from './services/roadmap.service';

import { RegisterComponent } from './pages/register/register.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UserPipe } from './pipes/user.pipe';
import { BoardSettingsComponent } from './components/board-settings/board-settings.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { AddTagComponent } from './components/add-tag/add-tag.component';
import { TagPipe } from './pipes/tag.pipe';
import { TagColorPipe } from './pipes/tag-color.pipe';
import { TagComponent } from './pages/tag/tag.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { BoardSettingsTabComponent } from './components/board-settings-tab/board-settings-tab.component';
import { ChangelogListComponent } from './components/changelog-list/changelog-list.component';
import { AddChangelogComponent } from './components/add-changelog/add-changelog.component';
import { ChangelogDetailComponent } from './components/changelog-detail/changelog-detail.component';
import { QuillModule } from 'ngx-quill';
import { SelectPostsComponent } from './components/select-posts/select-posts.component';
import { ChangelogPipe } from './pipes/changelog.pipe';
import { ProjectSettingsTabComponent } from './components/project-settings-tab/project-settings-tab.component';
import { ModalComponent } from './components/modal/modal.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { AddRoadmapComponent } from './components/add-roadmap/add-roadmap.component';
import { RoadmapDetailComponent } from './components/roadmap-detail/roadmap-detail.component';
import { NgApexchartsModule } from 'ng-apexcharts';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { PostPipe } from './pipes/post.pipe';
import { RoadmapPipe } from './pipes/roadmap.pipe';
import { createCustomElement } from '@angular/elements';
import { APP_BASE_HREF } from '@angular/common';
import { WidgetComponent } from './widgets/widget/widget.component';
import { BoardWidgetComponent } from './components/board-widget/board-widget.component';
import { ScoringComponent } from './pages/scoring/scoring.component';
import { BoardPipe } from './pipes/board.pipe';
import { UpgradeComponent } from './components/upgrade/upgrade.component';

registerLocaleData(localeDe);

const config = {
  apiKey: 'AIzaSyC6xpo7PC8K0O4_ht5RdYZIMVEKtmN16Bg',
  authDomain: 'votingapp-426cb.firebaseapp.com',
  projectId: 'votingapp-426cb',
  storageBucket: 'votingapp-426cb.appspot.com',
  messagingSenderId: '556939607054',
  appId: '1:556939607054:web:41ea24626488ea4828105f',
  /* apiKey: 'AIzaSyCg_P2vFgO5Vj5BP4_Unpon5FY3tbSfUdo',
  authDomain: 'featurebeartest.firebaseapp.com',
  projectId: 'featurebeartest',
  storageBucket: 'featurebeartest.appspot.com',
  messagingSenderId: '367044390872',
  appId: '1:367044390872:web:c26e6d6fefeb053e168cad', */
};
@NgModule({
  declarations: [
    AppComponent,

    ProjectComponent,
    BoardComponent,
    BoardListComponent,

    SidebarComponent,
    AddProjectComponent,
    AddBoardComponent,
    AddPostComponent,
    LoginComponent,
    PostDetailComponent,
    RegisterComponent,
    UserPipe,
    BoardSettingsComponent,
    UserSettingsComponent,
    ForgotPasswordComponent,
    ProjectSettingsComponent,
    OnboardingComponent,
    AddTagComponent,
    TagPipe,
    TagColorPipe,
    TagComponent,
    LoginDialogComponent,
    BoardSettingsTabComponent,
    ChangelogListComponent,
    AddChangelogComponent,
    ChangelogDetailComponent,
    SelectPostsComponent,
    ChangelogPipe,
    ProjectSettingsTabComponent,
    ModalComponent,
    RoadmapComponent,
    AddRoadmapComponent,
    RoadmapDetailComponent,
    PostPipe,
    RoadmapPipe,
    WidgetComponent,
    BoardWidgetComponent,
    ScoringComponent,
    BoardPipe,
    UpgradeComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    TagInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgApexchartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: APP_BASE_HREF, useValue: '/' },
    AuthService,
    UserService,
    ProjectService,
    BoardService,
    PostService,
    TagService,
    CommentService,
    ChangelogService,
    RoadmapService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [WidgetComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    const custom = createCustomElement(WidgetComponent, { injector: injector });
    customElements.define('featurebear-widget', custom);
  }
  ngDoBootstrap() {}
}
