<app-add-project
  (closeModal)="closeModal()"
  *ngIf="showAddProjectModal"
></app-add-project>

<div class="flex flex-no-wrap">
  <app-sidebar></app-sidebar>
  <div class="py-8 px-12 w-full">
    <div class="flex w-full justify-between items-center mb-6 md:mb-12"></div>
    <div class="flex items-center justify-between mb-10">
      <h1 class="text-5xl font-bold text-gray-900">Projects</h1>
      <div
        (click)="addProject()"
        class="cursor-pointer w-10 h-10 bg-fb-red text-white rounded-full flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-plus"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
    </div>

    <div class="mt-10 -ml-6">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div
          class="mx-auto container py-5 px-6"
          *ngFor="let project of projects"
        >
          <div class="w-full">
            <div class="rounded">
              <div
                (click)="openBoards(project.id)"
                class="cursor-pointer w-full h-52 flex flex-col justify-between bg-white rounded-lg border border-gray-400 mb-6 py-5 px-4"
              >
                <div>
                  <div class="flex justify-between">
                    <h1 class="text-gray-800 text-xl font-semibold">
                      {{ project.name }}
                    </h1>
                    <img
                    class="w-12 h-auto"
                      [src]="project?.settings?.logo ? project?.settings?.logo : ''"
                    />
                  </div>
                  <p class="text-gray-400 text-sm font-medium">
                    {{ project.description }}
                  </p>
                </div>
                <div>
                  <div class="flex items-center justify-between text-gray-800">
                    <p class="text-sm font-bold">
                      {{ project.boards?.length }} Boards
                    </p>
                    <div
                      class="w-8 h-8 rounded-full bg-gray-800 text-white flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-pencil"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path
                          d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"
                        />
                        <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
