import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { Comment } from 'src/app/models/comment.model';
import { BoardService } from 'src/app/services/board.service';
import { CommentService } from 'src/app/services/comment.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';
import { ThrowStmt } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit {
  postId: string;
  board: Board;
  user: User;
  post: Post;
  boards: Board[];
  tags: Tag[] = [];
  showAddTag: boolean = false;
  showDeletePost: boolean = false;
  showComments: boolean = true;
  commentText: string = '';
  comments: Comment[] = [];
  showStatusList: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private postService: PostService,
    private projectService: ProjectService,
    private tagService: TagService,
    private commentService: CommentService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.postId = this.route.snapshot.paramMap.get('id');
    this.post = await this.postService.getPost(this.postId);
    this.board = await this.boardService.getBoard(this.post.boardId);
    this.user = await this.userService.getUser();

    await this.loadComments();

    if (this.board.admins.indexOf(this.user.id) < 0) {
      this.router.navigate(['/board-list/' + this.board.projectID]);
    }

    this.boards = this.boardService.getBoards();
    if (typeof this.boards === 'undefined') {
      this.boards = [];
      let project = await this.projectService.getProject(this.board.projectID);
      project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }
  }

  async setPost() {
    await this.postService.setPost(this.post);
  }

  async upvote(post: Post) {
    const hasUserUpvoted = post.upvotes.includes(this.user.id);

    if (hasUserUpvoted) {
      post.upvotes = post.upvotes.filter((userId) => userId !== this.user.id);
      this.user.upvotes = this.user.upvotes.filter(
        (postId) => postId !== post.id
      );
    } else {
      post.upvotes.push(this.user.id);
      this.user.upvotes.push(post.id);
    }
    await this.postService.setPost(post);
    await this.userService.setUser(this.user);
  }

  async loadComments() {
    this.comments = [];
    for (let commentId of this.post.comments) {
      this.comments.push(await this.commentService.getComment(commentId));
    }
  }

  back() {
    this.router.navigate(['/board/' + this.post.boardId]);
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }

  async showTags() {
    this.showAddTag = true;
    for (let tagId of this.board.tags) {
      const tag: Tag = await this.tagService.getTag(tagId);
      this.tags.push(tag);
    }
  }

  getTagStyle(tag: Tag) {
    const isTagSelected = this.post.tags.find(
      (selectedTag) => selectedTag === tag.id
    );
    if (!isTagSelected) {
      return { 'background-color': tag.color + '80' };
    } else {
      return { 'background-color': tag.color };
    }
  }

  async addTag(tag: Tag) {
    const isTagSelected = this.post.tags.find(
      (selectedTag) => selectedTag === tag.id
    );

    if (isTagSelected) {
      this.post.tags = this.post.tags.filter(
        (selectedTag) => selectedTag !== tag.id
      );
      tag.featureRequests = tag.featureRequests.filter(
        (selectedTag) => selectedTag !== this.postId
      );
    } else {
      this.http.post(environment.nodeUrl + 'notification/new-tag', {
        board: this.board,
        post: this.post,
        currentUser: this.user,
      });

      this.post.tags.push(tag.id);
      tag.featureRequests.push(this.postId);
    }

    await this.tagService.setTag(tag);

    await this.postService.setPost(this.post);
  }

  async deletePost() {
    this.user.upvotes = this.user.upvotes.filter(
      (postId) => postId !== this.post.id
    );
    this.user.createdPosts = this.user.createdPosts.filter(
      (postId) => postId !== this.post.id
    );
    await this.userService.setUser(this.user);

    this.board.posts = this.board.posts.filter(
      (postId) => postId !== this.post.id
    );
    await this.boardService.setBoard(this.board);

    let tagList: Tag[] = [];
    for (let tagId of this.board.tags) {
      const loadedTag = await this.tagService.getTag(tagId);
      tagList.push(loadedTag);
    }

    for (let tag of tagList) {
      const isPostInTag = tag.featureRequests.find(
        (postId) => postId === this.post.id
      );
      if (isPostInTag) {
        tag.featureRequests = tag.featureRequests.filter(
          (postId) => postId !== this.post.id
        );
        await this.tagService.setTag(tag);
      }
    }

    await this.postService.deletePost(this.post.id);
    this.back();
  }

  autoGrowTextZone(e) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 25 + 'px';
  }

  async addComment() {
    if (this.commentText === '') return;

    let comment: Comment = {
      comment: this.commentText,
      date: new Date(),
      user: this.user.id,
    };

    const commmentId = await this.commentService.createComment(comment);
    this.post.comments.push(commmentId);

    this.http
      .post(environment.nodeUrl + 'notification/new-comment', {
        board: this.board,
        post: this.post,
        currentUser: this.user,
      })
      .subscribe((t) => console.log(t));

    await this.postService.setPost(this.post);

    this.user.comments.push(commmentId);
    await this.userService.setUser(this.user);

    await this.loadComments();
    this.commentText = '';
  }

  async deleteComment(comment: Comment) {
    this.post.comments = this.post.comments.filter(
      (commentId) => commentId !== comment.id
    );
    await this.postService.setPost(this.post);

    let commentUser: User = await this.userService.getUserById(comment.user);
    commentUser.comments = commentUser.comments.filter(
      (commentId) => commentId !== comment.id
    );
    await this.userService.setUser(commentUser);

    await this.commentService.deleteComment(comment.id);
    await this.loadComments();
  }

  async setStatus(status: string) {
    this.post.status = this.postService.statusList[status];
    this.http.post(environment.nodeUrl + 'notification/new-status', {
      board: this.board,
      post: this.post,
      currentUser: this.user,
    });
    await this.postService.setPost(this.post);
    this.showStatusList = false;
  }

  openChangelog() {
    this.router.navigate(['/changelog-detail/' + this.post.changelog]);
  }

  openRoadmap() {
    this.router.navigate(['/roadmap/' + this.board.projectID]);
  }

  openImage(image) {
    var win = window.open(image, '_blank');
    win.focus();
  }
}
