import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { Roadmap } from 'src/app/models/roadmap.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { ChangelogService } from 'src/app/services/changlog.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-roadmap',
  templateUrl: './add-roadmap.component.html',
  styleUrls: ['./add-roadmap.component.css'],
})
export class AddRoadmapComponent implements OnInit {
  posts: Post[] = [];
  user: User;
  project: Project;
  projectId: string;
  boards: Board[];
  showPostDialog: boolean = false;
  title: string = '';
  description: string = '';
  startDate: Date;
  endDate: Date;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private postService: PostService,
    private changelogService: ChangelogService,
    private http: HttpClient,
    private roadmapService: RoadmapService
  ) {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.project = await this.projectService.getProject(this.projectId);
    this.user = await this.userService.getUser();
    this.boards = this.boardService.getBoards();

    if (typeof this.boards === 'undefined') {
      this.boards = [];

      this.project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }
    if (this.project.owner.indexOf(this.user.id) < 0) {
      this.router.navigate(['/changelog/' + this.projectId]);
    }
  }

  closeSelectPosts(posts: Post[]) {
    this.posts = posts;
    this.showPostDialog = false;
  }

  removePost(post) {
    this.posts = this.posts.filter((p) => p.id !== post.id);
  }

  openSelectPosts() {
    this.showPostDialog = true;
  }

  async save() {
    if (!this.title || !this.startDate || !this.endDate) return;

    let postIds = [];
    this.posts.forEach((post) => {
      postIds.push(post.id);
    });

    let roadmap: Roadmap = {
      color: this.getRandomColor(),
      endDate: this.endDate,
      startDate: this.startDate,
      featureRequests: postIds,
      name: this.title,
      projectId: this.projectId,
      description: this.description,
    };
    const roadmapId = await this.roadmapService.createRoadmap(roadmap);
    roadmap.id = roadmapId;

    this.project.roadmaps = this.project.roadmaps || [];

    this.project.roadmaps.push(roadmapId);
    await this.projectService.setProject(this.project);

    for (let post of this.posts) {
      post.roadmapId = roadmapId;
      await this.postService.setPost(post);
    }
    this.back();
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  back() {
    this.router.navigate(['/roadmap/' + this.projectId]);
  }
}
