import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
})
export class ScoringComponent implements OnInit, AfterContentChecked {
  projectId: string;
  boards: Board[] = [];
  menu = {
    r: -1,
    i: -1,
    c: -1,
    e: -1,
  };
  project: Project;
  tooltip_status = 0;
  showModal: boolean = false;
  posts: Post[] = [];
  loader: boolean = false;
  sort = {
    post: false,
    board: false,
    reach: false,
    impact: false,
    confidence: false,
    effort: false,
    score: false,
  };
  user: User;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private postService: PostService,
    private cdref: ChangeDetectorRef
  ) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async ngOnInit() {
    this.loader = true;
    this.user = await this.userService.getUser();

    this.projectId = this.route.snapshot.paramMap.get('id');

    const user: User = await this.userService.getUser();

    if (user.firstLogin) {
      this.router.navigate(['/onboarding']);
      return;
    }

    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      this.projectId = user.projects[0];
    }

    if (user.projects.indexOf(this.projectId) < 0) {
      this.router.navigate(['/board-list' + this.projectId]);
    }

    this.loadBoards();
  }

  async loadBoards() {
    this.boards = [];
    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      return;
    }
    this.project = await this.projectService.getProject(this.projectId);
    for (let boardId of this.project.boards) {
      this.boards.push(await this.boardService.getBoard(boardId));
    }

    this.boardService.setBoards(this.boards);

    for (let board of this.boards) {
      let boardPosts: Post[] = await this.postService.getPostOfBoard(board.id);
      this.posts = [...this.posts, ...boardPosts];
    }
    this.posts = this.posts.filter((p) => p.status !== 'Done');
    this.loader = false;
  }

  addRoadmap() {
    this.router.navigate(['roadmap/' + this.projectId + '/add-roadmap']);
  }

  openPost(post) {
    this.router.navigate(['/post/' + post.id]);
  }

  openBoard(boardId) {
    this.router.navigate(['/board/' + boardId]);
  }

  async setScore(post: Post, key, val) {
    post.scoring = post.scoring || {
      r: 0,
      i: 0,
      c: 0,
      e: 0,
      totalScore: 0,
    };

    post.scoring[key] = val;
    post.scoring.totalScore = this.getTotalScore(post);
    await this.postService.setPost(post);

    Object.keys(this.menu).forEach((k) => {
      this.menu[k] = -1;
    });
  }

  getScoreColor(key, val) {
    switch (key) {
      case 'r':
        switch (val) {
          case 20:
            return 'bg-red-200 text-red-700';
          case 40:
            return 'bg-purple-200 text-purple-700';
          case 60:
            return 'bg-yellow-200 text-yellow-700';
          case 80:
            return 'bg-indigo-200 text-indigo-700';
          case 100:
            return 'bg-green-200 text-green-700';
          default:
            return 'Not Set';
        }
      case 'i':
        switch (val) {
          case 0.25:
            return 'bg-red-200 text-red-700';
          case 0.5:
            return 'bg-purple-200 text-purple-700';
          case 1:
            return 'bg-yellow-200 text-yellow-700';
          case 3:
            return 'bg-indigo-200 text-indigo-700';
          case 5:
            return 'bg-green-200 text-green-700';
          default:
            return 'Not Set';
        }
      case 'c':
        switch (val) {
          case 50:
            return 'bg-red-200 text-red-700';
          case 80:
            return 'bg-yellow-200 text-yellow-700';
          case 100:
            return 'bg-green-200 text-green-700';
          default:
            return 'Not Set';
        }
      case 'e':
        switch (val) {
          case 0.5:
            return 'bg-green-200 text-green-700 ';
          case 1:
            return 'bg-indigo-200 text-indigo-700 ';
          case 2:
            return 'bg-yellow-200 text-yellow-700';
          case 3:
            return 'bg-purple-200 text-purple-700';
          case 4:
            return 'bg-red-200 text-red-700';
          default:
            return 'Not Set';
        }

      default:
        break;
    }
  }

  getScoringValue(key, val) {
    switch (key) {
      case 'r':
        switch (val) {
          case 20:
            return 'XS';
          case 40:
            return 'S';
          case 60:
            return 'M';
          case 80:
            return 'L';
          case 100:
            return 'XL';
          default:
            return 'Not Set';
        }

      case 'i':
        switch (val) {
          case 0.25:
            return 'XS';
          case 0.5:
            return 'S';
          case 1:
            return 'M';
          case 3:
            return 'L';
          case 5:
            return 'XL';
          default:
            return 'Not Set';
        }
      case 'c':
        switch (val) {
          case 50:
            return 'Low';
          case 80:
            return 'Medium';
          case 100:
            return 'High';
          default:
            return 'Not Set';
        }
      case 'e':
        switch (val) {
          case 0.5:
            return 'XS';
          case 1:
            return 'S';
          case 2:
            return 'M';
          case 3:
            return 'L';
          case 4:
            return 'XL';
          default:
            return 'Not Set';
        }
      default:
        break;
    }
  }

  getTotalScore(post: Post) {
    post.scoring = post.scoring || {
      r: 0,
      i: 0,
      c: 0,
      e: 0,
      totalScore: 0,
    };

    if (post.scoring.e === 0) return 0;

    let ric = post.scoring.r * post.scoring.i * post.scoring.c;
    let rice = ric / post.scoring.e;
    return Number((rice / 1000).toFixed(1));
  }

  sortBy(column) {
    switch (column) {
      case 'post':
        if (this.sort.post) {
          this.posts.sort((a, b) => {
            return a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()
              ? -1
              : 1;
          });
        } else {
          this.posts.reverse();
        }
        this.sort.post = !this.sort.post;
        break;
      case 'board':
        if (this.sort.board) {
          this.posts.sort((a, b) => {
            return a.boardId.toLocaleLowerCase() < b.boardId.toLocaleLowerCase()
              ? -1
              : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.boardId.toLocaleLowerCase() < a.boardId.toLocaleLowerCase()
              ? -1
              : 1;
          });
        }
        this.sort.board = !this.sort.board;
        break;
      case 'reach':
        if (this.sort.reach) {
          this.posts.sort((a, b) => {
            return a.scoring.r < b.scoring.r ? -1 : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.scoring.r < a.scoring.r ? -1 : 1;
          });
        }
        this.sort.reach = !this.sort.reach;
        break;
      case 'impact':
        if (this.sort.impact) {
          this.posts.sort((a, b) => {
            return a.scoring.i < b.scoring.i ? -1 : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.scoring.i < a.scoring.i ? -1 : 1;
          });
        }
        this.sort.impact = !this.sort.impact;
        break;
      case 'confidence':
        if (this.sort.confidence) {
          this.posts.sort((a, b) => {
            return a.scoring.c < b.scoring.c ? -1 : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.scoring.c < a.scoring.c ? -1 : 1;
          });
        }
        this.sort.confidence = !this.sort.confidence;
        break;
      case 'effort':
        if (this.sort.effort) {
          this.posts.sort((a, b) => {
            return a.scoring.e < b.scoring.e ? -1 : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.scoring.e < a.scoring.e ? -1 : 1;
          });
        }
        this.sort.effort = !this.sort.effort;
        break;
      case 'score':
        if (this.sort.score) {
          this.posts.sort((a, b) => {
            return a.scoring.totalScore < b.scoring.totalScore ? -1 : 1;
          });
        } else {
          this.posts.sort((a, b) => {
            return b.scoring.totalScore < a.scoring.totalScore ? -1 : 1;
          });
        }
        this.sort.score = !this.sort.score;
        break;
      default:
        break;
    }
  }
}
