import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  resetForm: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService) {
    this.createForm();
  }

  createForm() {
    this.resetForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit(): void {}

  async reset() {
    if (!this.resetForm.valid) return;
    await this.authService.sendPasswordResetEmail(
      this.resetForm.controls['email'].value
    );

    this.close()
  }

  close() {
    this.closeModal.emit(true);
  }
}
