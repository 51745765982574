<div class="flex flex-no-wrap">
  <app-sidebar [projectId]="projectId"></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
      <div class="flex items-center justify-between mt-10 mb-5">
        <div class="flex space-x-4">
          <h1 class="text-5xl font-bold text-gray-900">Changelog</h1>
        </div>
        <div
          [routerLink]="['/changelog/' + projectId + '/add-changelog']"
          class="cursor-pointer w-10 h-10 bg-fb-red text-white rounded-full flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-plus"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </div>
      </div>

      <div *ngIf="loader">
        <div
          class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-300"
        ></div>
        <div
          class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-200"
        ></div>
        <div
          class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-100"
        ></div>
        <div class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-50"></div>
      </div>

      <div *ngIf="!loader" class="container mx-auto py-10">
        <div class="shadow bg-white dark:bg-gray-800 rounded">
          <div
            (click)="openChangelog(changelog)"
            *ngFor="let changelog of changelogs"
            class="cursor-pointer xl:w-full w-11/12 mx-auto flex flex-wrap items-center justify-between xl:px-8 md:px-8 lg:px-8 mb-2 xl:mb-0 lg:mb-0 border-b border-gray-300 dark:border-gray-700"
          >
            <div
              class="xl:w-3/5 lg:w-2/4 w-full pt-6 xl:pb-6 lg:pb-8 md:pb-8 sm:pb-8"
            >
              <p
                class="text-lg font-bold text-gray-800 dark:text-gray-100 pb-1"
              >
                {{ changelog.title }}
              </p>
            </div>
            <div
              class="xl:w-1/5 lg:w-1/4 w-full pt-6 pb-8 xl:pb-8 lg:pb-8 md:pb-8 sm:pb-8"
            >
              <div class="flex items-center xl:-ml-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-calendar-event"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#718096"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x="4" y="5" width="16" height="16" rx="2" />
                  <line x1="16" y1="3" x2="16" y2="7" />
                  <line x1="8" y1="3" x2="8" y2="7" />
                  <line x1="4" y1="11" x2="20" y2="11" />
                  <rect x="8" y="15" width="2" height="2" />
                </svg>
                <p class="text-sm text-gray-600 dark:text-gray-400 pl-2">
                  {{ changelog.date.toDate() | date: "short" }}
                </p>
              </div>
            </div>
            <div class="pt-6 pb-8">
              <div class="flex items-center w-full justify-between">
                <div
                  class="hidden md:flex text-gray-400 hover:text-gray-500 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-right"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="9 6 15 12 9 18" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
