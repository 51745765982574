import { Component, Input, OnInit } from '@angular/core';
import { Board } from 'src/app/models/board.model';

@Component({
  selector: 'app-board-widget',
  templateUrl: './board-widget.component.html',
  styleUrls: ['./board-widget.component.css'],
})
export class BoardWidgetComponent implements OnInit {
  @Input() board: Board;
  text: string;
  constructor() {}

  ngOnInit(): void {
    this.text =
      `<featurebear-widget  id="` +
      this.board.id +
      `"></featurebear-widget>
  <script
      type="text/javascript"
      src="https://featurebear.com/widget/featurebear-widget.js"
      async
    ></script>
  <link
      href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
      rel="stylesheet"
    />
  <link href="https://featurebear.com/widget/styles.css" rel="stylesheet" />
  `;
  }

  copyCode() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
