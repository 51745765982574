import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Changelog } from '../models/changelog.model';

@Injectable({
  providedIn: 'root',
})
export class ChangelogService {
  constructor(private db: AngularFirestore) {}

  async getChangelog(changelogId) {
    const snapshot = await this.db
      .collection('changelogs')
      .ref.doc(changelogId)
      .get();
    const changelog = snapshot.data() as Changelog;
    return changelog;
  }

  async createChangelog(changelog: Changelog) {
    const id = this.db.collection('changelogs').doc().ref.id;
    changelog.id = id;
    await this.db.collection('changelogs').doc(id).set(changelog);
    return id;
  }

  async setChangelog(changelog: Changelog) {
    return this.db
      .collection('changelogs')
      .doc(changelog.id)
      .set({ ...changelog });
  }

  async deleteChangelog(changelogId) {
    await this.db.collection('changelogs').doc(changelogId).delete();
  }
}
