<app-add-post
  (updateAddFeatureState)="setShowAddFeature($event)"
  [showAddFeature]="showAddFeature"
  [boardId]="boardId"
></app-add-post>

<app-board-settings
  (closeSettingsModal)="closeSettings()"
  [board]="board"
  *ngIf="showBoardSettings"
></app-board-settings>

<div class="flex flex-no-wrap">
  <app-sidebar
    *ngIf="board"
    [projectId]="board?.projectID"
    [boardId]="boardId"
    [boards]="boards"
  ></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
      <div *ngIf="loader">
        <div
          class="my-2 rounded-xl animate-pulse h-20 w-full bg-gray-300"
        ></div>

        <div
          class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-300"
        ></div>
        <div
          class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-300"
        ></div>
      </div>

      <div
        *ngIf="!loader"
        (click)="back()"
        class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
      >
        <svg
          class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">
          back
        </p>
      </div>
      <div *ngIf="!loader" class="flex items-center justify-between mt-10 mb-5">
        <div class="flex space-x-4">
          <h1 class="text-5xl font-bold text-gray-900" *ngIf="board">
            {{ board?.name }}
          </h1>
          <a
            [href]="'https://app.featurebear.com/' + board.id"
            target="_blank"
            class="
              h-auto
              flex
              items-center
              transition
              duration-150
              ease-in-out
              hover:bg-gray-200
              rounded
              text-blue-600
              pl-3
              pr-3
              text-sm
              cursor-pointer
            "
          >
            <span class="h-4 w-4 text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </span>
          </a>
        </div>

        <div
          (click)="showAddFeature = !showAddFeature"
          class="
            cursor-pointer
            w-10
            h-10
            bg-fb-red
            text-white
            rounded-full
            flex
            justify-center
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-plus"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </div>
      </div>

      <div *ngIf="!loader" class="flex space-x-2">
        <div class="w-full xl:mx-0 h-12 sm:block bg-white shadow rounded">
          <ul class="flex">
            <li
              (click)="this.active_status = 1"
              [ngClass]="
                active_status == 1
                  ? 'text-sm border-fb-red pt-3 rounded-t text-gray-600 ml-2 mr-12'
                  : 'text-sm text-gray-600 py-3 flex items-center ml-2 mr-12  cursor-pointer'
              "
            >
              <div class="flex items-center mb-3">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-home"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                </span>
                <span class="ml-1 font-normal">Board</span>
              </div>
              <div
                *ngIf="active_status == 1"
                class="w-full h-1 bg-fb-red rounded-t-md"
              ></div>
            </li>
            <li
              (click)="this.active_status = 2"
              [ngClass]="
                active_status == 2
                  ? 'text-sm border-fb-red pt-3 rounded-t text-gray-700 mr-12'
                  : 'text-sm text-gray-600 py-3 flex items-center mr-12  cursor-pointer'
              "
            >
              <div class="flex items-center mb-3">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                </span>
                <span class="ml-1 font-normal">Widget</span>
              </div>
              <div
                *ngIf="active_status == 2"
                class="w-full h-1 bg-fb-red rounded-t-md"
              ></div>
            </li>
            <li
              (click)="this.active_status = 3"
              [ngClass]="
                active_status == 3
                  ? 'text-sm border-fb-red pt-3 rounded-t text-gray-700 mr-12'
                  : 'text-sm text-gray-600 py-3 flex items-center mr-12  cursor-pointer'
              "
            >
              <div class="flex items-center mb-3">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </span>
                <span class="ml-1 font-normal">Settings</span>
              </div>
              <div
                *ngIf="active_status == 3"
                class="w-full h-1 bg-fb-red rounded-t-md"
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="active_status === 1">
        <div *ngIf="loader">
          <div
            class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-300"
          ></div>
          <div
            class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-300"
          ></div>
          <div
            class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-200"
          ></div>
          <div
            class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-100"
          ></div>
          <div
            class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-50"
          ></div>
        </div>

        <div
          class="
            flex
            justify-between
            mt-6
            flex-col
            md:flex-row md:space-x-10
            space-y-4
            sm:-space-y-0
          "
        >
          <div class="relative w-full md:w-1/4">
            <div
              (click)="this.show_list = !this.show_list"
              class="
                relative
                z-0
                bg-gray-100 bg-white
                flex
                items-center
                justify-between
                rounded
                w-full
                cursor-pointer
                text-xs
                form-select
                block
                p-4
                rounded
                bg-transparent
              "
              data-menu
            >
              <p
                class="
                  leading-3
                  tracking-normal
                  font-normal
                  text-gray-600 text-sm
                "
              >
                Sort:
                <span class="text-indigo-700 mr-2">{{ activeSort }}</span>
              </p>
              <div class="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="hidden icon icon-tabler icon-tabler-chevron-up"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 15 12 9 18 15" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-chevron-up"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
            </div>
            <ul
              *ngIf="show_list"
              class="
                z-10
                bg-white
                transition
                duration-300
                bg-white
                shadow
                rounded
                mt-2
                w-40
                py-1
                absolute
              "
            >
              <li
                (click)="sortBy('Upvotes')"
                class="
                  cursor-pointer
                  text-gray-600 text-sm
                  leading-3
                  tracking-normal
                  py-3
                  hover:bg-gray-100
                  px-3
                  font-normal
                "
              >
                Upvotes
              </li>
              <li
                (click)="sortBy('Latest')"
                class="
                  cursor-pointer
                  text-gray-600 text-sm
                  leading-3
                  tracking-normal
                  py-3
                  hover:bg-gray-100
                  px-3
                  font-normal
                "
              >
                Latest
              </li>
              <li
                (click)="sortBy('Done')"
                class="
                  cursor-pointer
                  text-gray-600 text-sm
                  leading-3
                  tracking-normal
                  py-3
                  hover:bg-gray-100
                  px-3
                  font-normal
                "
              >
                Done
              </li>
            </ul>
          </div>

          <div class="flex w-full">
            <div class="w-full">
              <div class="border border-gray-400 relative flex items-center">
                <div class="absolute ml-4 cursor-pointer">
                  <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-196.000000, -4105.000000)"
                        fill="#718096"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(152.000000, 4017.000000)">
                          <g transform="translate(32.000000, 72.000000)">
                            <g>
                              <g>
                                <path
                                  d="M22.0506576,25.0542101 L25.8342802,28.8449993 C26.0709604,29.125199 26.0520423,29.5403831 25.7908611,29.7978957
                                                          C25.5296798,30.0554083 25.1142853,30.0684363 24.8374817,29.8277965 L21.0608788,26.0510473 C18.7190814,27.8702712
                                                          15.3664691,27.5551481 13.4046533,25.3314133 C11.4428375,23.1076785 11.5480113,19.7418025 13.6448344,17.6448982
                                                          C15.7416575,15.5479938 19.107403,15.4428159 21.3310516,17.4047078 C23.5547002,19.3665996 23.8698111,22.7193419
                                                          22.0506576,25.0612301 L22.0506576,25.0542101 Z M17.5263158,25.5789474 C19.7645224,25.5789474 21.5789474,23.7645224
                                                          21.5789474,21.5263158 C21.5789474,19.2881092 19.7645224,17.4736842 17.5263158,17.4736842 C15.2881092,17.4736842
                                                          13.4736842,19.2881092 13.4736842,21.5263158 C13.4736842,23.7645224 15.2881092,25.5789474 17.5263158,25.5789474 Z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  class="focus:outline-none pl-10 w-full h-full py-3 text-sm"
                  id="Search"
                  aria-label="Search"
                  [(ngModel)]="filterText"
                  (input)="filterList()"
                />
                <!-- <button
            class="flex text-sm items-center border-gray-400 border-l text-gray-600 focus:outline-none py-3 px-6"
          >
            <span class="pr-1">
              <svg
                width="15px"
                height="14px"
                viewBox="0 0 15 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>Shape</title>
                <desc>Created with Sketch.</desc>
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-783.000000, -4105.000000)"
                    fill="#718096"
                    fill-rule="nonzero"
                  >
                    <g transform="translate(152.000000, 4017.000000)">
                      <g transform="translate(32.000000, 72.000000)">
                        <g>
                          <g>
                            <g transform="translate(575.000000, 0.000000)">
                              <g transform="translate(24.000000, 16.000000)">
                                <g>
                                  <path
                                    d="M0.210147303,3.99279876 C0.0781648052,3.86346678 0.00263177825,3.68722305
                                                                          7.38076267e-13,3.50245505 L7.38076267e-13,0.700491011 C7.38076267e-13,0.313620508 0.313620508,0
                                                                          0.700491011,0 L13.3093292,0 C13.6961997,0 14.0098202,0.313620508 14.0098202,0.700491011
                                                                          L14.0098202,3.50245505 C14.0071884,3.68722305 13.9316554,3.86346678 13.7996729,3.99279876
                                                                          L9.10638314,8.70009835 L9.10638314,10.5073652 C9.10375136,10.6921332 9.02821833,10.8683769
                                                                          8.89623584,10.9977089 L6.09427179,13.7996729 C5.89414129,13.9958943 5.5963129,14.053934
                                                                          5.33714717,13.9472187 C5.07798143,13.8405034 4.90737388,13.5895783 4.90343708,13.3093292
                                                                          L4.90343708,8.69309344 L0.210147303,3.99980367 L0.210147303,3.99279876 Z M1.40098202,1.40098202
                                                                          L1.40098202,3.21525374 L6.09427179,7.90854351 C6.22796446,8.03958537 6.30364195,8.21868878
                                                                          6.3044191,8.40589213 L6.3044191,11.6211459 L7.70540112,10.2201638 L7.70540112,8.40589213
                                                                          C7.7080329,8.22112413 7.78356592,8.0448804 7.91554842,7.91554842 L12.6088382,3.20824883
                                                                          L12.6088382,1.40098202 L1.40098202,1.40098202 Z"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            Filter
          </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="mt-20" *ngIf="posts?.length === 0">
          <h1 class="text-gray-500 text-xl">It's empty here</h1>
          <h2 class="text-gray-500 text-md">
            Let's start and create your first post
          </h2>
        </div>
        <div
          *ngFor="let post of posts"
          class="
            bg-white
            border border-gray-200
            hover:shadow-lg
            duration-500
            pt-1
            my-8
          "
        >
          <div
            class="
              duration-300
              flex
              sm:flex-row
              flex-col
              sm:items-center sm:justify-start
              justify-center
              items-center
            "
          >
            <!-- Meta Column -->
            <div class="flex-none w-full p-2 md:w-24 mb-4 text-center">
              <!-- Answer Counts -->
              <a
                (click)="upvote(post)"
                [ngClass]="{
                  'bg-green-500 text-white': user?.upvotes.includes(post.id),
                  'bg-green-50 ': !user?.upvotes.includes(post.id)
                }"
                class="
                  group
                  flex flex-col
                  justify-center
                  items-center
                  py-1
                  w-full
                  border
                  rounded-md
                  md:hover:bg-green-500
                  cursor-pointer
                "
              >
                <svg
                  class="md:group-hover:text-white w-6"
                  [ngClass]="{
                    'text-white': user?.upvotes.includes(post.id),
                    'text-green-400': !user?.upvotes.includes(post.id)
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <div
                  class="
                    inline-block
                    font-medium
                    text-lg
                    md:group-hover:text-white
                  "
                  [ngClass]="{
                    'text-white': user?.upvotes.includes(post.id),
                    'text-green-400': !user?.upvotes.includes(post.id)
                  }"
                >
                  {{ post.upvotes.length }}
                </div>
              </a>
            </div>

            <!-- Summary Column -->
            <div class="flex flex-col w-full flex-grow">
              <div
                class="
                  md:-mb-4
                  space-x-2
                  sm:px-0
                  mt-1
                  px-4
                  hidden
                  w-full
                  md:flex
                  items-center
                  md:justify-start
                  justify-between
                  flex-row
                "
              >
                <div class="flex">
                  <svg
                    class="w-4 mr-1 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="text-xs text-gray-400">
                    {{ post?.date_created.toDate() | date: "short" }}</span
                  >
                </div>
                <div class="flex">
                  <svg
                    class="text-sm text-gray-400 w-4 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  <div class="font-medium text-xs text-gray-400">
                    {{ post.creator | user | async }}
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="
                      border
                      h-5
                      w-auto
                      px-2
                      mb-4
                      md:mb-0
                      rounded-md
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <div class="flex items-center">
                      <span class="text-xs text-gray-500 font-normal"
                        >Status: {{ post.status }}</span
                      >
                    </div>
                  </div>
                </div>
                <!--             <div class="mr-4 ml-4"></div>
            <svg
              class="text-sm text-gray-400 w-4 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
            <div class="font-medium text-xs text-gray-400">
              {{ post.comments.length }}
            </div> -->
              </div>
              <div
                (click)="openPost(post.id)"
                class="
                  md:mt-5
                  -mt-4
                  justify-center
                  text-center
                  md:text-left
                  cursor-pointer
                "
              >
                <div
                  class="
                    md:hidden
                    xs:flex
                    flex-col
                    h-5
                    px-2
                    mb-4
                    md:mb-0
                    rounded-md
                    items-center
                    justify-center
                  "
                >
                  <div class="flex justify-center items-center">
                    <span
                      class="
                        border
                        px-2
                        justify-center
                        text-center text-xs text-gray-500
                        font-normal
                      "
                      >{{ post.status }}</span
                    >
                  </div>
                </div>
                <a
                  class="
                    sm:text-sm
                    md:text-md
                    lg:text-lg
                    text-gray-700
                    font-bold
                  "
                >
                  {{ post.title }}
                </a>
              </div>
              <!-- Question Labels -->
              <div
                class="
                  mt-2
                  md:mb-2
                  flex flex-row
                  md:justify-start
                  justify-center
                "
              >
                <!-- Categories  -->
                <div class="col-span-12 lg:col-span-8 mb-2">
                  <a
                    *ngFor="let tag of post.tags"
                    [ngStyle]="{
                      'background-color': tag | tagColor | async
                    }"
                    (click)="openTag(tag)"
                    class="
                      cursor-pointer
                      inline-block
                      rounded-full
                      text-white
                      duration-300
                      text-xs
                      font-bold
                      mr-1
                      md:mr-2
                      px-2
                      md:px-4
                      py-1
                      opacity-90
                      hover:opacity-100
                    "
                  >
                    {{ tag | tag | async }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-board-widget
        [board]="board"
        *ngIf="active_status === 2"
      ></app-board-widget>

      <app-board-settings-tab
        class="mt-6"
        *ngIf="active_status === 3"
        [board]="board"
      ></app-board-settings-tab>
    </div>
  </div>
</div>
