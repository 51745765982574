import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';
import { loadStripe, Stripe, StripeElement } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  user: User;
  boards: Board[];
  showDeleteUser: boolean = false;
  showLoginModal: boolean = false;
  stripe: Stripe;

  constructor(
    private userService: UserService,
    private boardService: BoardService,
    private authService: AuthService,
    private tagService: TagService,
    private projectService: ProjectService,
    private postService: PostService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUser();

    this.stripe = await loadStripe('pk_test_l6wODTU08vXkGcHiLEwQeoGO');
    if (this.route.snapshot.queryParams.session_id) {
      fetch(
        'https://stark-falls-13689.herokuapp.com/stripe/getSession/' +
          this.route.snapshot.queryParams.session_id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then(async (sessionObj) => {
          const session = await sessionObj.json();
          this.user.billing = this.user.billing || {
            price: 0,
            startDate: new Date(),
            stripeCustomerId: '',
            tier: 'PREMIUM',
            stripeSubscriptionId: '',
          };
          this.user.billing.tier = 'PREMIUM';
          this.user.billing.stripeCustomerId = session.customer;
          this.user.billing.stripeSubscriptionId = session.subscription;
          await this.userService.setUser(this.user);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  public closeModal() {
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
  }

  async save() {
    await this.userService.setUser(this.user);
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.remove('hidden');
    setTimeout(function () {
      Notification.style.transform = 'translateX(0%)';
    }, 1000);
  }

  deleteUser() {
    this.showLoginModal = true;
  }

  async closeLoginModal() {
    for (let projectId of this.user.projects) {
      const project: Project = await this.projectService.getProject(projectId);

      for (let boarId of project.boards) {
        const board = await this.boardService.getBoard(boarId);
        for (let tagId of board.tags) {
          await this.tagService.deleteTag(tagId);
        }

        for (let postId of board.posts) {
          await this.postService.deletePost(postId);
        }

        for (let userId of board.registeredUsers) {
          let user: User = await this.userService.getUserById(userId);
          user.boards = user.boards.filter((boardId) => boardId !== board.id);
          await this.userService.setUser(user);
        }

        await this.boardService.deleteBoard(board.id);
      }
      await this.projectService.deleteProject(projectId);
    }

    await this.userService.deleteUser(this.user.id);

    await this.authService.deleteUser(this.user.id);
    this.router.navigate(['/login']);
  }

  async subscribe() {
    await fetch(
      'https://stark-falls-13689.herokuapp.com/stripe/create-session',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          email: this.user.email,
        }),
      }
    ).then(async (test) => {
      const sessionId = await test.json();

      // Call Stripe.js method to redirect to the new Checkout page
      this.stripe
        .redirectToCheckout({
          sessionId: sessionId.sessionId,
        })
        .then((res) => {
          console.log(res);
        });
    });
  }
}
