import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
})
export class BoardComponent implements OnInit {
  flag = true;
  showAddFeature: boolean = false;
  showMenu: boolean = false;
  boardId: string;
  board: Board;
  originalPosts: Post[];

  posts: Post[];
  loader: boolean = false;
  user: User;
  boards: Board[] = [];
  showBoardSettings: boolean = false;
  tags: Tag[] = [];
  show_list = false;
  filterText: string = '';
  activeSort: string = 'Upvotes';
  active_status = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private postService: PostService,
    private projectService: ProjectService,
    private tagService: TagService
  ) {}

  async ngOnInit() {
    this.boardId = this.route.snapshot.paramMap.get('id');
    this.board = await this.boardService.getBoard(this.boardId);
    this.user = await this.userService.getUser();

    if (this.board.admins.indexOf(this.user.id) < 0) {
      this.router.navigate(['/project']);
    }

    this.route.params.subscribe(async (params: Params) => {
      this.boardId = params['id'];
      this.loadPosts();
    });

    this.boards = this.boardService.getBoards();
    if (typeof this.boards === 'undefined') {
      this.boards = [];
      let project = await this.projectService.getProject(this.board.projectID);
      project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }

    //this.loadPosts();
  }

  async setShowAddFeature(state) {
    if (state === false) {
      await this.loadPosts();
      this.showAddFeature = state;
    } else {
      this.showAddFeature = !state;
    }
  }

  async loadPosts() {
    this.loader = true;
    this.posts = [];
    this.user = await this.userService.getUser();
    this.posts = await this.postService.getPostOfBoard(this.boardId);
    this.posts = this.posts.filter(p=> p.status !== 'Done')
    this.sortPosts();
    this.loader = false;
    this.originalPosts = Object.assign([], this.posts);
  }

  sortPosts() {
    this.posts.sort(function (a, b) {
      return b.upvotes.length - a.upvotes.length;
    });
  }

  async upvote(post: Post) {
    const hasUserUpvoted = post.upvotes.includes(this.user.id);

    if (hasUserUpvoted) {
      post.upvotes = post.upvotes.filter((userId) => userId !== this.user.id);
      this.user.upvotes = this.user.upvotes.filter(
        (postId) => postId !== post.id
      );
    } else {
      post.upvotes.push(this.user.id);
      this.user.upvotes.push(post.id);
    }
    await this.postService.setPost(post);
    await this.userService.setUser(this.user);
    this.user = await this.userService.getUser();

    this.sortPosts();
  }

  filterList() {
    if (this.filterText === '') {
      this.posts = this.originalPosts;
      this.sortBy(this.activeSort);
      return;
    }

    this.posts = Object.assign([], this.originalPosts).filter(
      (item) =>
        item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
    );
  }

  async sortBy(sortString) {
    this.posts = this.originalPosts;
    switch (sortString) {
      case 'Upvotes':
        this.sortPosts();
        break;
      case 'Latest':
        this.posts.sort(function (a, b) {
          const bDate: any = b.date_created as Object;
          const aDate: any = a.date_created as Object;
          return (
            new Date(bDate.seconds * 1000).getTime() -
            new Date(aDate.seconds * 1000).getTime()
          );
        });
        break;
      case 'Done':
        this.posts = await this.postService.getPostByStatusOfBoard(
          this.boardId,
          'Done'
        );
        break;
      default:
        break;
    }
    this.show_list = false;
    this.activeSort = sortString;
  }

  async getTag(tagId) {
    let tag: Tag;

    const isTagLoaded = this.tags.find((t) => t.id === tagId);

    if (isTagLoaded) {
      tag = isTagLoaded;
    } else {
      tag = await this.tagService.getTag(tagId);
    }

    return tag;
  }

  openPost(postId) {
    this.router.navigate(['/post/' + postId]);
  }

  back() {
    this.router.navigate(['/board-list', this.board.projectID]);
  }

  async closeSettings() {
    this.showBoardSettings = false;
    await this.loadPosts();
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }
}
