import { Component, Input, OnInit } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css'],
})
export class UpgradeComponent implements OnInit {
  @Input() user: User;
  stripe: Stripe;

  constructor() {}

  async ngOnInit() {
    this.stripe = await loadStripe('pk_test_l6wODTU08vXkGcHiLEwQeoGO');
  }

  async subscribe() {
    await fetch(
      'https://stark-falls-13689.herokuapp.com/stripe/create-session',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          email: this.user.email,
        }),
      }
    ).then(async (test) => {
      const sessionId = await test.json();

      // Call Stripe.js method to redirect to the new Checkout page
      this.stripe
        .redirectToCheckout({
          sessionId: sessionId.sessionId,
        })
        .then((res) => {
          console.log(res);
        });
    });
  }
}
