<div class="flex flex-no-wrap">
  <app-sidebar
    *ngIf="projectId"
    [projectId]="projectId"
    [boards]="boards"
  ></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
      <div class="flex items-center justify-between mt-10 mb-5">
        <h1 class="text-5xl font-bold text-gray-900" *ngIf="project">
          Scoring
        </h1>
      </div>

      <div class="mx-auto container py-3">
        <div class="mx-auto container bg-white dark:bg-gray-800 shadow rounded">
          <div
            class="mx-auto container bg-white dark:bg-gray-800 dark:bg-gray-800 shadow rounded"
          >
            <div *ngIf="loader">
              <div
                class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-300"
              ></div>
              <div
                class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-200"
              ></div>
              <div
                class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-100"
              ></div>
              <div
                class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-50"
              ></div>
            </div>

            <div
              *ngIf="!loader"
              class="w-full overflow-x-scroll xl:overflow-x-hidden"
            >
              <table class="min-w-full bg-white dark:bg-gray-800">
                <thead>
                  <tr class="w-full h-16 border-gray-300 border-b py-8">
                    <th
                      (click)="sortBy('post')"
                      class="cursor-pointer pl-8 text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      Post
                    </th>
                    <th
                      (click)="sortBy('board')"
                      class="cursor-pointer text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      Board
                    </th>
                    <th
                      (click)="sortBy('reach')"
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="relative mt-20 md:mt-0"
                        (mouseover)="tooltip_status = 1"
                        (mouseout)="tooltip_status = 0"
                      >
                        <div
                          class="ml-2 cursor-pointer flex space-x-2 items-center"
                        >
                          <p>Reach</p>
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-info-circle"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        <div
                          *ngIf="tooltip_status == 1"
                          role="tooltip"
                          class="z-20 absolute transition duration-150 ease-in-out top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white text-gray-600 rounded w-56"
                        >
                          How many users will this feature affect?
                        </div>
                      </div>
                    </th>
                    <th
                      (click)="sortBy('impact')"
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="relative mt-20 md:mt-0"
                        (mouseover)="tooltip_status = 2"
                        (mouseout)="tooltip_status = 0"
                      >
                        <div
                          class="ml-2 cursor-pointer flex space-x-2 items-center"
                        >
                          <p>Impact</p>
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-info-circle"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        <div
                          *ngIf="tooltip_status == 2"
                          role="tooltip"
                          class="z-20 absolute transition duration-150 ease-in-out top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white text-gray-600 rounded w-56"
                        >
                          How big is the impact of this feature for your users?
                        </div>
                      </div>
                    </th>
                    <th
                      (click)="sortBy('confidence')"
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="relative mt-20 md:mt-0"
                        (mouseover)="tooltip_status = 3"
                        (mouseout)="tooltip_status = 0"
                      >
                        <div
                          class="ml-2 cursor-pointer flex space-x-2 items-center"
                        >
                          <p>Confidence</p>
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-info-circle"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        <div
                          *ngIf="tooltip_status == 3"
                          role="tooltip"
                          class="z-20 absolute transition duration-150 ease-in-out top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white text-gray-600 rounded w-56"
                        >
                          How confident are you that you can implement this
                          feature?
                        </div>
                      </div>
                    </th>
                    <th
                      (click)="sortBy('effort')"
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="relative mt-20 md:mt-0"
                        (mouseover)="tooltip_status = 4"
                        (mouseout)="tooltip_status = 0"
                      >
                        <div
                          class="ml-2 cursor-pointer flex space-x-2 items-center"
                        >
                          <p>Effort</p>
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-info-circle"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        <div
                          *ngIf="tooltip_status == 4"
                          role="tooltip"
                          class="z-20 absolute transition duration-150 ease-in-out top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white text-gray-600 rounded w-56"
                        >
                          How much effort will this feature need?
                        </div>
                      </div>
                    </th>
                    <th
                      (click)="sortBy('score')"
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="relative mt-20 md:mt-0"
                        (mouseover)="tooltip_status = 5"
                        (mouseout)="tooltip_status = 0"
                      >
                        <div
                          class="ml-2 cursor-pointer flex space-x-2 items-center"
                        >
                          <p>Score</p>
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-info-circle"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#A0AEC0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                            <polyline points="11 12 12 12 12 16 13 16" />
                          </svg>
                        </div>
                        <div
                          *ngIf="tooltip_status == 5"
                          role="tooltip"
                          class="z-20 absolute transition duration-150 ease-in-out top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white text-gray-600 rounded w-56"
                        >
                          The higher the score, the sooner you should implement
                          this feature.
                        </div>
                      </div>
                    </th>
                    <th
                      class="text-gray-600 dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4"
                    >
                      <div
                        class="opacity-0 w-2 h-2 rounded-full bg-indigo-400"
                      ></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-24 border-gray-300 border-b"
                    *ngFor="let post of posts; let i = index"
                  >
                    <td
                      (click)="openPost(post)"
                      class="pl-8 cursor-pointer text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4 hover:underline"
                    >
                      {{ post.title }}
                    </td>
                    <td
                      (click)="openBoard(post.boardId)"
                      class="text-sm cursor-pointer pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4 hover:underline"
                    >
                      {{ post.boardId | board | async }}
                    </td>

                    <td
                      class="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4"
                    >
                      <div class="relative">
                        <div
                          *ngIf="menu?.r === i"
                          class="dropdown-content -mt-8 absolute left-0 shadow-md z-10 w-32"
                        >
                          <ul
                            class="bg-white dark:bg-gray-800 shadow rounded py-1"
                          >
                            <li
                              (click)="setScore(post, 'r', 20)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-red-200 hover:text-red-700 px-2 font-normal"
                            >
                              XS
                            </li>
                            <li
                              (click)="setScore(post, 'r', 40)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-purple-200 hover:text-purple-700 px-2 font-normal"
                            >
                              S
                            </li>
                            <li
                              (click)="setScore(post, 'r', 60)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-yellow-200 hover:text-yellow-700 px-2 font-normal"
                            >
                              M
                            </li>
                            <li
                              (click)="setScore(post, 'r', 80)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-indigo-200 hover:text-indigo-700 px-2 font-normal"
                            >
                              L
                            </li>
                            <li
                              (click)="setScore(post, 'r', 100)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-green-200 hover:text-green-700 px-2 font-normal"
                            >
                              XL
                            </li>
                          </ul>
                        </div>
                        <div
                          (click)="menu?.r !== i ? (menu.r = i) : (menu.r = -1)"
                          class="cursor-pointer flex space-x-2 justify-center text-center rounded-full text-sm leading-3 py-2 px-5"
                          [ngClass]="
                            post.scoring
                              ? getScoreColor('r', post.scoring?.r)
                              : 'bg-indigo-200 text-indigo-700'
                          "
                        >
                          <p>{{ getScoringValue("r", post.scoring?.r) }}</p>
                          <svg
                            class="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td
                      class="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4"
                    >
                      <div class="relative">
                        <div
                          *ngIf="menu?.i === i"
                          class="dropdown-content -mt-8 absolute left-0 shadow-md z-10 w-32"
                        >
                          <ul
                            class="bg-white dark:bg-gray-800 shadow rounded py-1"
                          >
                            <li
                              (click)="setScore(post, 'i', 0.25)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-red-200 hover:text-red-700 px-2 font-normal"
                            >
                              XS
                            </li>
                            <li
                              (click)="setScore(post, 'i', 0.5)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-purple-200 hover:text-purple-700 px-2 font-normal"
                            >
                              S
                            </li>
                            <li
                              (click)="setScore(post, 'i', 1)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-yellow-200 hover:text-yellow-700 px-2 font-normal"
                            >
                              M
                            </li>
                            <li
                              (click)="setScore(post, 'i', 3)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-indigo-200 hover:text-indigo-700 px-2 font-normal"
                            >
                              L
                            </li>
                            <li
                              (click)="setScore(post, 'i', 5)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-green-200 hover:text-green-700 px-2 font-normal"
                            >
                              XL
                            </li>
                          </ul>
                        </div>
                        <div
                          (click)="menu?.i !== i ? (menu.i = i) : (menu.i = -1)"
                          class="cursor-pointer flex space-x-2 justify-center text-center rounded-full text-sm leading-3 py-2 px-5"
                          [ngClass]="
                            post.scoring
                              ? getScoreColor('i', post.scoring?.i)
                              : 'bg-indigo-200 text-indigo-700'
                          "
                        >
                          <p>{{ getScoringValue("i", post.scoring?.i) }}</p>
                          <svg
                            class="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="pr-6 whitespace-no-wrap">
                      <div class="relative">
                        <div
                          *ngIf="menu?.c === i"
                          class="dropdown-content -mt-8 absolute left-0 shadow-md z-10 w-32"
                        >
                          <ul
                            class="bg-white dark:bg-gray-800 shadow rounded py-1"
                          >
                            <li
                              (click)="setScore(post, 'c', 50)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-red-200 hover:text-red-700 px-2 font-normal"
                            >
                              Low
                            </li>
                            <li
                              (click)="setScore(post, 'c', 80)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-yellow-200 hover:text-yellow-700 px-2 font-normal"
                            >
                              Medium
                            </li>
                            <li
                              (click)="setScore(post, 'c', 100)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-green-200 hover:text-green-700 px-2 font-normal"
                            >
                              High
                            </li>
                          </ul>
                        </div>
                        <div
                          (click)="menu?.c !== i ? (menu.c = i) : (menu.c = -1)"
                          class="cursor-pointer flex space-x-2 justify-center text-center rounded-full text-sm leading-3 py-2 px-5"
                          [ngClass]="
                            post.scoring
                              ? getScoreColor('c', post.scoring?.c)
                              : 'bg-indigo-200 text-indigo-700'
                          "
                        >
                          <p>{{ getScoringValue("c", post.scoring?.c) }}</p>
                          <svg
                            class="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td
                      class="text-sm pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4"
                    >
                      <div class="relative">
                        <div
                          *ngIf="menu?.e === i"
                          class="dropdown-content -mt-8 absolute left-0 shadow-md z-10 w-32"
                        >
                          <ul
                            class="bg-white dark:bg-gray-800 shadow rounded py-1"
                          >
                            <li
                              (click)="setScore(post, 'e', 0.5)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-green-200 hover:text-green-700 px-2 font-normal"
                            >
                              XS
                            </li>
                            <li
                              (click)="setScore(post, 'e', 1)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-indigo-200 hover:text-indigo-700 px-2 font-normal"
                            >
                              S
                            </li>
                            <li
                              (click)="setScore(post, 'e', 2)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-yellow-200 hover:text-yellow-700 px-2 font-normal"
                            >
                              M
                            </li>
                            <li
                              (click)="setScore(post, 'e', 3)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-purple-200 hover:text-purple-700 px-2 font-normal"
                            >
                              L
                            </li>
                            <li
                              (click)="setScore(post, 'e', 4)"
                              class="cursor-pointer rounded-full text-center flex justify-center text-gray-600 dark:text-gray-400 text-xs leading-3 tracking-normal py-2 hover:bg-red-200 hover:text-red-700 px-2 font-normal"
                            >
                              XL
                            </li>
                          </ul>
                        </div>
                        <div
                          (click)="menu?.e !== i ? (menu.e = i) : (menu.e = -1)"
                          class="cursor-pointer flex space-x-2 justify-center text-center rounded-full text-sm leading-3 py-2 px-5"
                          [ngClass]="
                            post.scoring
                              ? getScoreColor('e', post.scoring?.e)
                              : 'bg-indigo-200 text-indigo-700'
                          "
                        >
                          <p>{{ getScoringValue("e", post.scoring?.e) }}</p>
                          <svg
                            class="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td
                      class="text-sm pl-6 pr-6 whitespace-no-wrap text-gray-800 dark:text-gray-100 tracking-normal leading-4"
                    >
                      {{ getTotalScore(post) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
