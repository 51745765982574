import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css'],
})
export class LoginDialogComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  loginForm: FormGroup;
  showError: boolean =  false;
  constructor(
    private fb: FormBuilder,
    
    private authService: AuthService
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.minLength(6)],
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit(): void {}

  login() {
    if (!this.loginForm.valid) {
      this.showError = true;
      return;
    }
    console.log(this.loginForm);

    const credentials = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.authService
      .signInWithEmail(credentials)
      .then(async () => {

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  close() {
    this.closeModal.emit(true);
  }

}
