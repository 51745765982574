import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import * as firebase from 'firebase/app';

@Injectable()
export class AuthService {
  public token: string;

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private userService: UserService
  ) {}

  public onSuccess(): void {
    sessionStorage.setItem('session-alive', 'true');
    this.token = 'some-temporary-token';
    this.router.navigate(['/']);
    console.log('AUTH: ', this.auth);
  }

  public logout(): void {
    sessionStorage.removeItem('session-alive');
    this.token = null;
    this.router.navigate(['/login']);
  }

  public isAuthenticated(): string {
    return sessionStorage.getItem('session-alive');
  }

  isLoggedIn() {
    return new Promise<any>((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (typeof user === 'undefined' || user === null) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  signUp(credentials) {
    return this.auth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signInWithEmail(credentials) {
    console.log('Sign in with email');
    return this.auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signInWithGoogle() {
    return this.auth.signInWithPopup(
      new firebase.default.auth.GoogleAuthProvider()
    );
  }

  signInWithGithub() {
    return this.auth.signInWithPopup(
      new firebase.default.auth.GithubAuthProvider()
    );
  }

  signOut(): Promise<void> {
    this.userService.userData = null;
    this.userService.user = null;
    return this.auth.signOut();
  }

  async sendEmailVerification() {
    const user = await this.auth.currentUser;
    user.sendEmailVerification();
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.auth.sendPasswordResetEmail(passwordResetEmail);
  }

  async deleteUser(userId) {
    const user = await this.auth.currentUser;
    user.delete();
  }
}
