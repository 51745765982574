import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
})
export class BoardListComponent implements AfterViewInit {
  showAddBoardModal: boolean = false;
  projectId: string;
  project: Project;
  boards: Board[] = [];
  showProjectSettings: boolean = false;
  active_status: number = 1;
  user: User;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngAfterViewInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.cdRef.detectChanges();

    this.user = await this.userService.getUser();

    if (this.user.firstLogin) {
      this.router.navigate(['/onboarding']);
      return;
    }

    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      this.projectId = this.user.projects[0];
      this.cdRef.detectChanges();
    }

    if (this.user.projects.indexOf(this.projectId) < 0) {
      this.router.navigate(['/board-list' + this.projectId]);
    }

    this.loadBoards();
  }

  async loadBoards() {
    this.boards = [];
    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      return;
    }
    this.project = await this.projectService.getProject(this.projectId);
    this.project.boards.forEach(async (boardId) => {
      this.boards.push(await this.boardService.getBoard(boardId));
    });
    this.boardService.setBoards(this.boards);
  }

  add() {
    this.showAddBoardModal = true;
  }

  openBoard(boardId) {
    this.router.navigate(['/board/' + boardId]);
  }

  closeModal() {
    this.showAddBoardModal = false;
    this.loadBoards();
  }

  closeSettingsModal() {
    this.showProjectSettings = false;
  }
}
