<div class="flex flex-no-wrap">
  <app-sidebar></app-sidebar>
  <div class="py-8 w-full">
    <div class="flex items-center justify-center mb-5 mt-10">
      <h1 class="text-5xl font-bold text-gray-900">Let's start</h1>
    </div>
    <div class="flex justify-center mt-10">
      <div class="p-5 w-full md:w-3/5">
        <div class="mx-4 p-4">
          <div class="flex items-center">
            <div class="flex items-center text-teal-600 relative">
              <div
                [ngClass]="{
                  'bg-red-400 border-red-400 text-white': step === 0
                }"
                class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-teal-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-bookmark"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="12 4 4 8 12 12 20 8 12 4" />
                  <polyline points="4 12 12 16 20 12" />
                  <polyline points="4 16 12 20 20 16" />
                </svg>
              </div>
              <div
                class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600"
              >
                Project
              </div>
            </div>
            <div
              class="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600"
            ></div>
            <div class="flex items-center text-gray-500 relative">
              <div
                [ngClass]="{
                  'bg-red-400 border-red-400 text-white': step === 1
                }"
                class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user-plus"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </div>
              <div
                class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-600"
              >
                Board
              </div>
            </div>
            <div
              class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
            ></div>
            <div class="flex items-center text-gray-500 relative">
              <div
                [ngClass]="{
                  'bg-red-400 border-red-400 text-white': step === 2
                }"
                class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-mail"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
              </div>
              <div
                class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase"
              >
                Post
              </div>
            </div>
            <div
              class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
            ></div>
            <div class="flex items-center text-gray-500 relative">
              <div
                [ngClass]="{
                  'bg-red-400 border-red-400 text-white': step === 3
                }"
                class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-database"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </svg>
              </div>
              <div
                class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
              >
                Ready
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 p-4">
          <div *ngIf="step === 0">
            <div
              class="font-bold text-center text-gray-600 text-xl leading-8 h-6 mx-2 mb-10 mt-3"
            >
              What's the name of your project?
            </div>

            <div class="flex flex-col md:flex-row">
              <div class="w-full flex-1 mx-2 svelte-1l8159u">
                <form [formGroup]="projectForm" novalidate>
                  <div
                    class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
                  >
                    <input
                      formControlName="name"
                      placeholder="Project Name"
                      [ngClass]="{
                        'border-red-400 border':
                          projectForm.controls['name'].invalid &&
                          (projectForm.controls['name'].dirty ||
                            projectForm.controls['name'].touched)
                      }"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div *ngIf="step === 1">
            <div
              class="font-bold text-center text-gray-600 text-xl leading-8 h-6 mb-10 mx-2 mt-3"
            >
              Create your first board (e.g. Feature, Bugs, Ideas)
            </div>
            <div class="flex flex-col md:flex-row">
              <div class="w-full flex-1 mx-2 svelte-1l8159u">
                <form [formGroup]="boardForm" novalidate>
                  <div
                    class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
                  >
                    <input
                      placeholder="Board Name"
                      formControlName="name"
                      [ngClass]="{
                        'border-red-400 border':
                          boardForm.controls['name'].invalid &&
                          (boardForm.controls['name'].dirty ||
                            boardForm.controls['name'].touched)
                      }"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div *ngIf="step === 2">
            <div
              class="font-bold text-center text-gray-600 text-xl leading-8 mb-12 h-6 mx-2 mt-3"
            >
              Add a first request to your board (e.g. Dark Mode)
            </div>
            <form [formGroup]="postForm" novalidate>
              <div class="flex flex-col md:flex-row mt-8">
                <div class="w-full flex-1 mx-2 svelte-1l8159u">
                  <div
                    class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
                  >
                    <input
                      formControlName="title"
                      [ngClass]="{
                        'border-red-400 border':
                          postForm.controls['title'].invalid &&
                          (postForm.controls['title'].dirty ||
                            postForm.controls['title'].touched)
                      }"
                      placeholder="Request Name"
                      class="p-1 px-2 appearance-none border outline-none w-full text-gray-800"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="step === 3">
            <div
              class="font-bold text-center text-gray-600 text-xl leading-8 h-6 mb-6 mx-2 mt-3"
            >
              Here is the link to your board 🎉
            </div>
            <div class="flex flex-col md:flex-row">
              <div class="w-full flex-1 mx-2 svelte-1l8159u">
                <div
                  class="bg-white mx-2 my-2 p-1  border border-gray-200 rounded svelte-1l8159u"
                >
                  <div
                    class="w-full bg-gray-100 space-y-3 flex flex-col md:flex-row rounded-lg  items-center justify-between p-4"
                  >
                    <input
                      class="text-base w-full font-medium leading-6 dark:text-gray-100 text-gray-800"
                      disabled
                      [value]="
                        'https://app.featurebear.com/' + currentUser.boards[0]
                      "
                    />
                    <div
                      (click)="copyUrl()"
                      class="cursor-pointer text-xs font-semibold leading-4 text-indigo-700 uppercase"
                    >
                      COPY
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div class="flex p-2 mt-4">
            <div class="flex-auto flex justify-center">
              <button
                (click)="addStep()"
                class="text-base ml-2 hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white hover:bg-red-300 bg-red-400 bg-teal-600 border duration-200 ease-in-out border-red-300 transition"
              >
                {{ step < 3 ? "Next" : "Finish" }}
              </button>
            </div>
            <button
              (click)="openProjects()"
              class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-teal-200 bg-teal-100 text-teal-700 duration-200 ease-in-out border-teal-600 transition"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
