<div
  id="member-modal"
  class="fixed z-10 pt-10 left-0 top-0 w-full h-full overflow-auto shadow-lg display: none;"
  style="background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.4)"
>
  <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
    <div
      class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
    >
      <form [formGroup]="boardForm" novalidate>
        <h1
          class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4"
        >
          Board Settings
        </h1>
        <label
          for="name"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Name</label
        >
        <input
          id="name"
          formControlName="name"
          [ngClass]="{
            'border-red-400':
              boardForm.controls['name'].invalid &&
              (boardForm.controls['name'].dirty ||
                boardForm.controls['name'].touched)
          }"
          class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
          placeholder="Features"
        />
        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Description</label
        >
        <div class="relative mb-5 mt-2">
          <div
            class="absolute right-0 text-gray-600 flex items-center pr-3 h-full"
          ></div>
          <textarea
            id="description"
            formControlName="description"
            rows="3"
            [ngClass]="{
              'border-red-400':
                boardForm.controls['description'].invalid &&
                (boardForm.controls['description'].dirty ||
                  boardForm.controls['description'].touched)
            }"
            class="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full flex items-center pl-3 text-sm border-gray-300 rounded border"
            placeholder=""
          ></textarea>
        </div>

        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Logo</label
        >
        <div class="flex justify-between mb-5 mt-2">
          <!-- Code block starts -->
          <div class="cursor-pointer">
            <label
              id="getFileLabel"
              for="getFile"
              class="flex bg-white relative items-center px-3 py-2 border rounded mr-4 mt-4 cursor-pointer"
            >
              <p class="mr-2 text-xs text-gray-600">Change Board Logo</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            </label>

            <input
              type="file"
              id="getFile"
              class="hidden"
              (change)="upload($event)"
              accept=".png,.jpg"
            />
          </div>
          <div class="h-12 w-12 mb-4 mt-2 lg:mb-0 mr-4">
            <img
              *ngIf="board.settings.logo"
              [src]="board.settings.logo"
              alt=""
              class="h-full w-full rounded-full overflow-hidden shadow"
            />
          </div>
        </div>

        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Show FeatureBear Logo</label
        >
        <div class="flex relative mb-5 mt-2">
          <!-- Code block starts -->
          <div
            class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
          >
            <input
              checked
              formControlName="fbLogoVisibility"
              type="checkbox"
              name="toggleFbLogo"
              id="toggleFbLogo"
              class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
            />
            <label
              for="toggleFbLogo"
              class="toggle-label bg-gray-200 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>

          <!-- Code block ends -->
          <style>
            .checkbox:checked {
              /* Apply class right-0*/
              right: 0;
            }
            .checkbox:checked + .toggle-label {
              /* Apply class bg-indigo-700 */
              background-color: #4c51bf;
            }
          </style>
        </div>

        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Disable Post Requests</label
        >
        <div class="flex relative mb-5 mt-2">
          <!-- Code block starts -->
          <div
            class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
          >
            <input
              checked
              formControlName="disablePost"
              type="checkbox"
              name="toggleDisablePost"
              id="toggleDisablePost"
              class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
            />
            <label
              for="toggleDisablePost"
              class="toggle-label bg-gray-200 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>

          <!-- Code block ends -->
          <style>
            .checkbox:checked {
              /* Apply class right-0*/
              right: 0;
            }
            .checkbox:checked + .toggle-label {
              /* Apply class bg-indigo-700 */
              background-color: #4c51bf;
            }
          </style>
        </div>

        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Access</label
        >
        <div class="flex relative mb-5 mt-2">
          <!-- Code block starts -->
          <div
            class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
          >
            <input
              checked
              formControlName="visibility"
              type="checkbox"
              name="toggle1"
              id="toggle1"
              class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
            />
            <label
              for="toggle1"
              class="toggle-label bg-gray-200 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <p class="mx-2">
            {{ boardForm.controls["visibility"].value ? "Live" : "Draft" }}
          </p>
          <!-- Code block ends -->
          <style>
            .checkbox:checked {
              /* Apply class right-0*/
              right: 0;
            }
            .checkbox:checked + .toggle-label {
              /* Apply class bg-indigo-700 */
              background-color: #4c51bf;
            }
          </style>
        </div>

        <label
          for="description"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Only registrated Users can vote</label
        >
        <div class="flex relative mb-10 mt-2">
          <!-- Code block starts -->
          <div
            class="cursor-pointer w-12 h-6 rounded-full bg-gray-700 relative shadow-sm"
          >
            <input
              checked
              disabled
              type="checkbox"
              name="toggle2"
              id="toggle2"
              class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
            />
            <label
              for="toggle2"
              class="toggle-label bg-gray-200 block w-12 h-6 overflow-hidden rounded-full cursor-pointer"
            ></label>
          </div>
          <p class="mx-2">Yes</p>
          <!-- Code block ends -->
          <style>
            #toggle2:checked {
              /* Apply class right-0*/
              right: 0;
            }
            #toggle2:checked + .toggle-label {
              /* Apply class bg-indigo-700 */
              background-color: gray;
            }
          </style>
        </div>

        <div class="flex items-center justify-start mb-10 w-full">
          <button
            *ngIf="!showDeleteBoard"
            (click)="showDeleteBoard = true"
            class="cancel-member focus:outline-none bg-red-600 transition duration-150 text-white ease-in-out hover:border-red-400 hover:bg-red-400 border rounded px-8 py-2 text-sm"
          >
            Delete Board
          </button>
          <div
            *ngIf="showDeleteBoard"
            class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
          >
            <div class="flex pb-3 items-center">
              <div class="-ml-1 text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trash"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </div>
              <p class="text-lg text-gray-800 font-semibold pl-2">
                Delete this Board?
              </p>
            </div>
            <p class="text-sm text-gray-600 pb-3 font-normal">
              Do you really want to delete this board?
            </p>
            <button
              (click)="deleteBoard()"
              class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs"
            >
              Delete
            </button>
          </div>
        </div>

        <div class="flex items-center justify-start w-full">
          <button
            (click)="close()"
            class="cancel-member focus:outline-none bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
          >
            Close
          </button>
          <button
            (click)="save()"
            class="focus:outline-none ml-3 transition duration-150 ease-in-out hover:bg-red-600 bg-fb-red rounded text-white px-8 py-2 text-sm"
          >
            Save
          </button>
        </div>
      </form>

      <svg
        (click)="close()"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Close"
        class="close-member cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </div>
  </div>
</div>
