import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Plan, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: boolean = false;
  showForgotPassword: boolean = false;
  freePlan: Plan = {
    name: 'free',
    period: 'monthly',
    price: 0,
  };
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
    private userService: UserService
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
      },
      { updateOn: 'submit' }
    );
  }

  ngOnInit(): void {}

  login() {
    if (!this.loginForm.valid) {
      this.showError = true;
      return;
    }

    const credentials = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.authService
      .signInWithEmail(credentials)
      .then(() => {
        this.zone.run(() => {
          this.router.navigate(['/board-list']);
        });
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  signInWithGoogle() {
    this.authService
      .signInWithGoogle()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification: { newTag: true, newStatus: true, newComment: true },
          };
          await this.userService.setUser(registeredUser);
        }

        this.zone.run(() => {
          this.router.navigate(['/board-list']);
        });
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  signInWithGithub() {
    this.authService
      .signInWithGithub()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification: { newTag: true, newStatus: true, newComment: true },
          };
          await this.userService.setUser(registeredUser);
        }

        this.zone.run(() => {
          this.router.navigate(['/board-list']);
        });
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  openRegister() {
    this.router.navigate(['/register']);
  }
}
