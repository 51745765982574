<div
  id="member-modal"
  class="fixed z-10 pt-10 left-0 top-0 w-full h-full overflow-auto shadow-lg display: none;"
  style="background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.4)"
>
  <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
    <div
      class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
    >
      <div class="mx-auto container">
        <div class="flex justify-end">
          <svg
            class="h-6 w-6 text-gray-600 cursor-pointer"
            (click)="close()"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div *ngFor="let board of boardList">
          <div class="flex justify-between pt-6 items-center">
            <div class="flex">
              <div data-menu class="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  (click)="board.expand = !board.expand"
                  *ngIf="!board.expand"
                  class="icon icon-tabler icon-tabler-chevron-up"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#718096"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 15 12 9 18 15" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  (click)="board.expand = !board.expand"
                  *ngIf="board.expand"
                  class="icon icon-tabler icon-tabler-chevron-down"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#718096"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z"></path>
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </div>
              <h1
                class="text-lg text-gray-900 dark:text-gray-100 font-semibold ml-3"
              >
                {{ board.name }} Board
              </h1>
            </div>
          </div>
          <ul class="pt-6" *ngIf="board.expand">
            <li
              class="md:flex justify-between items-center"
              *ngFor="let post of board.postList"
            >
              <div class="flex items-center text-gray-400 w-full md:w-1/2">
                <div
                  class="mx-4 bg-white border rounded-sm border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center relative"
                >
                  <input
                    type="checkbox"
                    (change)="setSelectedPosts(post)"
                    class="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                  />
                  <div
                    class="check-icon bg-yellow-400 text-white rounded-sm hidden"
                  >
                    <svg
                      class="icon icon-tabler icon-tabler-check"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  </div>
                </div>
                <p class="text-gray-600 dark:text-gray-400 font-semibold">
                  {{ post.title }}
                </p>
                <div class="ml-2 flex items-center">
                  <span class="text-xs text-gray-500 font-normal">{{
                    post.status
                  }}</span>
                </div>
              </div>
              <hr class="my-5 border-t border-gray-300 dark:border-gray-200" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
