<div class="flex flex-no-wrap">
  <app-sidebar
    *ngIf="projectId"
    [projectId]="projectId"
    [boards]="boards"
  ></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
    <div class="flex items-center justify-between mt-10 mb-5">
      <h1 class="text-5xl font-bold text-gray-900" *ngIf="project">Roadmap</h1>

      <div
        (click)="addRoadmap()"
        class="cursor-pointer w-10 h-10 bg-fb-red text-white rounded-full flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-plus"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
    </div>

    <div id="chart" *ngIf="chartOptions && roadmapItems.length > 0">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [fill]="chartOptions.fill"
        [grid]="chartOptions.grid"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
      ></apx-chart>
    </div>

    <div
      class="mx-auto container py-3"
      *ngFor="let roadmap of roadmapItems; let i = index"
    >
      <div class="mt-16 flex items-center justify-between text-gray-700">
       <div (click)="openRoadmap(roadmap)" class="flex space-x-3 items-end">
        <h3
          
          class="cursor-pointer text-3xl text-gray-900 dark:text-gray-100 font-semibold"
        >
          {{ roadmap.name }} 
        </h3>
        <p class="cursor-pointer text-lg text-gray-500 dark:text-gray-100 ">({{roadmap.startDate.toDate() | date}} -  {{roadmap.endDate.toDate() | date}})</p>
      </div>
        <!-- Code block starts -->
        <div class="relative">
          <div
            (click)="menu !== i ? (menu = i) : (menu = -1)"
            class="bg-white dark:bg-gray-800 dark:border-gray-700 flex justify-between rounded mx-4 cursor-pointer"
            data-menu
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer dark:text-gray-100 text-gray-900 icon icon-tabler icon-tabler-dots"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z"></path>
              <circle cx="5" cy="12" r="1"></circle>
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
            </svg>
          </div>
          <ul
            *ngIf="menu === i"
            class="transition duration-300 opacity-100 bg-white dark:bg-gray-800 shadow rounded mt-2 py-1 w-48 absolute"
          >
            <li
              (click)="openRoadmap(roadmap)"
              class="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center font-normal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-tag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              <span class="ml-2">Edit</span>
            </li>
            <li
              (click)="deleteRoadmap(roadmap)"
              class="cursor-pointer text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center font-normal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              <span class="ml-2">Delete</span>
            </li>
          </ul>
        </div>
        <!-- Code block ends -->
      </div>

      <hr class="mb-8 mt-6 border-t border-gray-300 dark:border-gray-700" />
      <p class="text-sm text-gray-400" *ngIf="roadmap.description">
        Description
      </p>
      <p class="mb-8">{{ roadmap.description }}</p>
      <p
        class="text-sm mb-5 text-gray-400"
        *ngIf="roadmap.featureRequests.length > 0"
      >
        Linked Posts
      </p>
      <ul class="mx-2" *ngFor="let post of roadmap.featureRequests">
        <li (click)="openPost(post)" class="flex items-center mb-6">
          <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <p class="cursor-pointer text-sm text-gray-800 dark:text-gray-100">
            {{ post | post | async }}
          </p>
        </li>
      </ul>
    </div>
    <style>
      .checkbox:checked + .check-icon {
        display: flex;
      }
    </style>
  </div>
</div>

<app-modal [data]="modalData" (closeModal)="closeModal($event)" *ngIf="showModal"></app-modal>