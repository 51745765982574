import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Board } from '../models/board.model';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  boards: Board[];
  constructor(private db: AngularFirestore, private afStorage: AngularFireStorage) {}

  async getBoard(boardId) {
    const snapshot = await this.db.collection('boards').ref.doc(boardId).get();
    const board = snapshot.data() as Board;
    return board;
  }

  async createBoard(board: Board) {
    const id = this.db.collection('boards').doc().ref.id;
    board.id = id;
    await this.db.collection('boards').doc(id).set(board);
    return id;
  }

  async setBoard(board: Board) {
    return this.db
      .collection('boards')
      .doc(board.id)
      .set({ ...board });
  }

  setBoards(boards: Board[]) {
    this.boards = boards;
  }

  getBoards() {
    return this.boards;
  }

  uploadBoardPicture(board,picture) {
   return this.afStorage.upload('/picture/'+board.id, picture);  
  }

  async deleteBoard(boardId){
    await this.db.collection("boards").doc(boardId).delete();
  }
}
