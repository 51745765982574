import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
 /*  selector: 'app-widget', */
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom 
})
export class WidgetComponent implements OnInit {
  active_status: number = 1;
  urlString: SafeResourceUrl;
  @Input() id : string;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const chatModal = document.querySelector('.chat-modal');
    const chatServices = document.querySelector('.chat-services');

    const showChat = document.querySelector('.show-chat');
    const closeChat = document.querySelector('.close-chat');
    this.urlString = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://app.featurebear.com/"+ this.id + "?widget=true"
    );

    showChat.addEventListener('click', function () {
      chatModal.classList.add('show');
      showChat.classList.add('hidden');
      setTimeout(() => {
        chatServices.classList.add('expand');
      }, 500);
    });
    closeChat.addEventListener('click', function () {
      setTimeout(() => {
        showChat.classList.remove('hidden');
      }, 820);
      chatServices.classList.remove('expand');
      setTimeout(() => {
        chatModal.classList.remove('show');
      }, 500);
    });
  }

  openFeatureBear() {}
}
