import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoardListComponent } from './pages/board-list/board-list.component';
import { BoardComponent } from './pages/board/board.component';
import { LoginComponent } from './pages/login/login.component';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';
import { ProjectComponent } from './pages/project/project.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterComponent } from './pages/register/register.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { TagComponent } from './pages/tag/tag.component';
import { AddChangelogComponent } from './components/add-changelog/add-changelog.component';
import { ChangelogDetailComponent } from './components/changelog-detail/changelog-detail.component';
import { ChangelogListComponent } from './components/changelog-list/changelog-list.component';
import { ProjectSettingsTabComponent } from './components/project-settings-tab/project-settings-tab.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { RoadmapDetailComponent } from './components/roadmap-detail/roadmap-detail.component';
import { AddRoadmapComponent } from './components/add-roadmap/add-roadmap.component';
import { WidgetComponent } from './widgets/widget/widget.component';
import { ScoringComponent } from './pages/scoring/scoring.component';

const routes: Routes = [
  /* { path: 'calendar-day-view', component: CalendarDayViewComponent },
    { path: 'calendar-month-view', component: CalendarMonthViewComponent },
    { path: 'calendar-week-view', component: CalendarWeekViewComponent }, */
  { path: 'project', component: ProjectComponent, canActivate: [AuthGuard] },
  /*  { path: 'inbox', component: InboxComponent }, */
  { path: '', component: BoardListComponent, canActivate: [AuthGuard] },
  { path: 'board/:id', component: BoardComponent, canActivate: [AuthGuard] },
  { path: 'tag/:id', component: TagComponent, canActivate: [AuthGuard] },
  {
    path: 'changelog-detail/:id',
    component: ChangelogDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changelog/:id',
    component: ChangelogListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'board-list/:id',
    component: BoardListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'board-list',
    component: BoardListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'roadmap/:id',
    component: RoadmapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'roadmap/:id/detail/:roadmapId',
    component: RoadmapDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'roadmap/:id/add-roadmap',
    component: AddRoadmapComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scoring/:id',
    component: ScoringComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changelog/:id/add-changelog',
    component: AddChangelogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/:id',
    component: ProjectSettingsTabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'widget',
    component: WidgetComponent,
    canActivate: [AuthGuard],
  },
  /*  { path: 'notes', component: NotesComponent },
    { path: 'project-files', component: ProjectFilesComponent },
    { path: 'project-links', component: ProjectLinksComponent },
    { path: 'project-notes', component: ProjectNotesComponent },
    { path: 'projects', component: ProjectsComponent},
    { path: 'project-todos', component: TodosComponent }, */
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'onboarding', component: OnboardingComponent },
  { path: 'post/:id', component: PostDetailComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
