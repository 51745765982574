<div class="flex flex-no-wrap">
  <app-sidebar *ngIf="project" [projectId]="project?.id"></app-sidebar>
  <div class="py-8 px-12 w-full" *ngIf="changelog">
    <div class="flex justify-between">
      <div
        (click)="back()"
        class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
      >
        <svg
          class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">
          back
        </p>
      </div>
    </div>

    <div class="container mx-auto shadow bg-white dark:bg-gray-800 rounded">
      <div
        class="xl:w-full flex px-4 justify-between border-b border-gray-300 dark:border-gray-700 py-5"
      >
        <div class="flex items-center w-11/12 mx-auto">
          <p class="text-lg text-gray-800 dark:text-gray-100 font-bold">
            {{ changelog.title }}
          </p>
        </div>
        <ul
          class="flex flex-col md:flex-row md:items-center text-gray-600 text-sm"
        >
          <li class="flex items-center md:mt-0">
            <a
              *ngIf="!showDeleteChangelog"
              (click)="showDeleteChangelog = true"
              class="my-2 flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 text-gray-600 p-4 rounded-full py-2 text-sm cursor-pointer"
            >
              <span class="h-4 w-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </span>
            </a>
          </li>
        </ul>
        <div
          *ngIf="showDeleteChangelog"
          class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
        >
          <div class="flex pb-3 items-center">
            <div class="-ml-1 text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-trash"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="4" y1="7" x2="20" y2="7" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </div>
            <p class="text-lg text-gray-800 font-semibold pl-2">
              Delete this Changelog Item?
            </p>
          </div>
          <p class="text-sm text-gray-600 pb-3 font-normal">
            Do you really want to delete this Changelog Item?
          </p>
          <button
            (click)="deleteChangelog()"
            class="focus:outline-none bg-red-400 transition duration-150 ease-in-out rounded text-white px-3 py-2 text-xs"
          >
            Delete
          </button>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div
          class="xl:w-9/12 mx-auto md:flex-row flex-col space-y-5 md:space-y-0 justify-between mt-10 flex xl:mx-0"
        >
          <div class="flex flex-col xl:w-2/6 w-full">
            <label
              for="title"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Title</label
            >
            <input
              type="text"
              id="title"
              name="title"
              (blur)="save(false)"
              [(ngModel)]="changelog.title"
              class="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800 bg-transparent dark:text-gray-100"
              placeholder="Version 2.0 is live"
              required
            />
          </div>
          <div class="flex flex-col xl:w-2/6 w-full">
            <label
              for="visibility"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Visibility</label
            >
            <div class="flex space-x-4">
              <p class="text-sm">{{ changelog.status ? "Live" : "Draft" }}</p>
              <div
                class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
              >
                <input
                  [checked]="changelog.status"
                  (change)="changelog.status = !changelog.status; save(false)"
                  type="checkbox"
                  name="toggle"
                  id="toggle2"
                  class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                />
                <label
                  for="toggle2"
                  class="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                ></label>
              </div>
              <!-- Code block ends -->
              <style>
                .checkbox:checked {
                  /* Apply class right-0*/
                  right: 0;
                }
                .checkbox:checked + .toggle-label {
                  /* Apply class bg-indigo-700 */
                  background-color: #ff7070;
                }
              </style>
            </div>
          </div>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div class="w-full xl:mx-0">
          <div class="mt-8 flex flex-col w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Changelog Text</label
            >

            <quill-editor
              class="w-full shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent resize-none text-gray-800 dark:text-gray-100"
              [(ngModel)]="changelog.text"
              (onSelectionChanged)="!$event.range ? save(false) :null"
              theme="snow"
            ></quill-editor>
          </div>

          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Link Posts to this Changlog</label
            >
            <button
              (click)="openSelectPosts()"
              class="my-2 bg-white transition duration-150 ease-in-out hover:border-indigo-600 hover:text-indigo-600 rounded border border-indigo-700 text-indigo-700 px-6 py-2 text-sm"
            >
              Add Post
            </button>
          </div>
          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              *ngIf="posts.length > 0"
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Linked Posts</label
            >

            <div class="flex flex-col">
              <div
                class="ml-2 m-2 flex space-x-6 items-center"
                *ngFor="let post of posts"
              >
                <p>{{ post.title }}</p>
                <div class="ml-2 flex items-center">
                  <span class="text-xs text-gray-500 font-normal">{{
                    post.status
                  }}</span>
                </div>

                <svg
                  (click)="removePost(post)"
                  class="h-4 w-4 text-fb-red"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Notify Users</label
            >
            <div class="">
              <div class="flex py-6">
                <div class="h-32 mb-2">
                  <!-- Code block starts -->
                  <div class="relative">
                    <div
                      (click)="showNotificationMenu = !showNotificationMenu"
                      class="bg-white dark:bg-gray-800 flex items-center justify-between border rounded border-gray-300 dark:border-gray-700 w-40 cursor-pointer"
                      data-menu
                    >
                      <p
                        class="pl-3 py-3 text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal font-normal"
                      >
                        {{ notification.text }}
                      </p>
                      <div
                        class="cursor-pointer text-gray-600 dark:text-gray-400 mr-3"
                      >
                        <svg
                          *ngIf="showNotificationMenu"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-chevron-up"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="6 15 12 9 18 15" />
                        </svg>
                        <svg
                          *ngIf="!showNotificationMenu"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-chevron-up"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </div>
                    </div>
                    <ul
                      *ngIf="showNotificationMenu"
                      class="visible transition duration-300 opacity-100 bg-white dark:bg-gray-800 shadow rounded mt-2 w-48 py-1 absolute"
                    >
                      <li
                        (click)="setNotification('none')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        None
                      </li>
                      <li
                        (click)="setNotification('commented')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who commented on a post
                      </li>
                      <li
                        (click)="setNotification('upvoted')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who upvoted a post
                      </li>
                      <li
                        (click)="setNotification('commentedUpvoted')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who commented or upvoted a post
                      </li>
                      <li
                        (click)="setNotification('all')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        All registered Users
                      </li>
                    </ul>
                  </div>

                  <button
                    *ngIf="notification.type !== 'none'"
                    (click)="notifyUser()"
                    class="items-center space-x-4 flex mt-4 btn text-sm focus:outline-none text-indigo-600 dark:text-indigo-400 border border-indigo-300 dark:hover:border-gray-500 py-2 px-6 mr-4 rounded hover:bg-indigo-200 transition duration-150 ease-in-out"
                  >
                    <p>Notify Users</p>
                    <svg
                      *ngIf="showCheck"
                      class="h-4 w-4"
                      [ngClass]="showCheck ? 'transition duration-350' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                  <!-- Code block ends -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full py-4 sm:px-12 px-4 bg-gray-100 dark:bg-gray-700 mt-6 flex justify-end rounded-bl rounded-br"
      >
        <a
        *ngIf="boards"
          [href]="getChangelogURL()"
          target="_blank"
          class="btn text-sm focus:outline-none text-gray-600 dark:text-gray-400 border border-gray-300 dark:hover:border-gray-500 py-2 px-6 mr-4 rounded hover:bg-gray-200 transition duration-150 ease-in-out"
        >
          Open Changelog
        </a>
      </div>
    </div>
  </div>
</div>

<app-modal [data]="modalData" (closeModal)="closeModal($event)" *ngIf="showModal"></app-modal>

<app-select-posts
  [boards]="boards"
  (closeSelectPosts)="closeSelectPosts($event)"
  *ngIf="showPostDialog && boards"
></app-select-posts>
