<div class="flex flex-no-wrap">
  <app-sidebar [projectId]="projectId"></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
    <div class="container mx-auto bg-white shadow rounded">
      <div class="xl:w-full border-b border-gray-300 py-5">
        <div class="flex items-center w-11/12 mx-auto">
          <div class="container mx-auto">
            <div class="mx-auto xl:w-full">
              <p class="text-lg text-gray-800 font-bold">Settings</p>
              <p class="text-sm text-gray-500 pt-1">Customize your project</p>
            </div>
          </div>
        </div>
      </div>
      <form *ngIf="projectForm" [formGroup]="projectForm" novalidate>
        <div class="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12">
          <div
            class="flex items-center mb-8 mt-12 text-gray-800 dark:text-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-bell"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p class="text-sm font-bold ml-2 text-gray-800 dark:text-gray-100">
              General
            </p>
          </div>

          <div class="flex justify-between items-center mb-8">
            <div class="w-9/12">
              <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">Name</p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                The name of your project. It will not appear on your public
                board.
              </p>
            </div>
            <div class="cursor-pointer relative shadow-sm">
              <input
                id="name"
                formControlName="name"
                (blur)="save()"
                [ngClass]="{
                  'border-red-400':
                    projectForm.controls['name'].invalid &&
                    (projectForm.controls['name'].dirty ||
                      projectForm.controls['name'].touched)
                }"
                class="mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm rounded border"
                placeholder="Features"
              />
            </div>
          </div>
          <div class="flex justify-between items-center mb-8">
            <div class="w-9/12">
              <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                Description
              </p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                A short description of your project. It will not appear on your
                public board.
              </p>
            </div>
            <div class="cursor-pointer rounded-full relative shadow-sm">
              <textarea
                id="description"
                formControlName="description"
                [ngClass]="{
                  'border-red-400':
                    projectForm.controls['description'].invalid &&
                    (projectForm.controls['description'].dirty ||
                      projectForm.controls['description'].touched)
                }"
                rows="3"
                (blur)="save()"
                class="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder=""
              ></textarea>
            </div>
          </div>
          <div class="flex justify-between items-center mb-8">
            <div class="w-9/12">
              <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                Project Logo
              </p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Change the logo of your board. It will appear on your public
                board.
              </p>
            </div>
            <div class="flex cursor-pointer rounded-full relative shadow-sm">
              <div class="cursor-pointer mt-6">
                <label
                  id="getFileLabel"
                  for="getFile"
                  class="flex bg-white items-center px-3 py-2 border rounded mr-4 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-edit"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path
                      d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                    />
                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                    <line x1="16" y1="5" x2="19" y2="8" />
                  </svg>
                </label>
                <input
                  type="file"
                  id="getFile"
                  class="hidden"
                  (change)="upload($event)"
                  accept=".png,.jpg"
                />
              </div>
              <div class="h-12 w-12 mt-2 lg:mb-0 mr-4">
                <img
                  *ngIf="project?.settings.logo"
                  [src]="project?.settings.logo"
                  alt=""
                  class="h-full w-full rounded-full overflow-hidden shadow"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-between items-center mb-8">
            <div class="w-9/12">
              <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                Link Boards
              </p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Shows all connected boards on your board page
              </p>
            </div>
            <div class="cursor-pointer rounded-full relative shadow-sm">
              <div class="flex relative mb-5 mt-2">
                <!-- Code block starts -->
                <p class="mx-2"></p>
                <div
                  class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
                >
                  <input
                    checked
                    formControlName="linkBoards"
                    (change)="save()"
                    type="checkbox"
                    name="toggle1"
                    id="toggle1"
                    class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                  />
                  <label
                    for="toggle1"
                    class="toggle-label bg-gray-200 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>

                <!-- Code block ends -->
                <style>
                  .checkbox:checked {
                    /* Apply class right-0*/
                    right: 0;
                  }
                  .checkbox:checked + .toggle-label {
                    /* Apply class bg-indigo-700 */
                    background-color: #ff7070;
                  }
                </style>
              </div>
            </div>
          </div>

          <div
            class="flex items-center mb-8 mt-12 text-gray-800 dark:text-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-bell"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            <p class="text-sm font-bold ml-2 text-gray-800 dark:text-gray-100">
              Delete
            </p>
          </div>

          <div class="flex justify-between items-center mb-8">
            <div class="w-9/12">
              <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                Delete
              </p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Delete this board
              </p>
            </div>
            <div class="cursor-pointer rounded-full relative shadow-sm">
              <div class="flex items-center justify-end mb-10 w-full">
                <button
                  *ngIf="!showDeleteBoard"
                  (click)="showDeleteBoard = true"
                  class="cancel-member focus:outline-none bg-red-600 transition duration-150 text-white ease-in-out hover:border-red-400 hover:bg-red-400 border rounded px-8 py-2 text-sm"
                >
                  Delete Project
                </button>
                <div
                  *ngIf="showDeleteBoard"
                  class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
                >
                  <div class="flex pb-3 items-center">
                    <div class="-ml-1 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-trash"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="4" y1="7" x2="20" y2="7" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                        <path
                          d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                        />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </div>
                    <p class="text-lg text-gray-800 font-semibold pl-2">
                      Delete this Project?
                    </p>
                  </div>
                  <p class="text-sm text-gray-600 pb-3 font-normal">
                    Do you really want to delete this project and all the boards
                    of it?
                  </p>
                  <button
                    (click)="deleteProject()"
                    class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <style>
        .checkbox:checked {
          /* Apply class right-0*/
          right: 0;
        }
        .checkbox:checked + .toggle-label {
          /* Apply class bg-indigo-700 */
          background-color: #ff7070;
        }
      </style>
    </div>
  </div>
</div>
