import { Injectable } from '@angular/core';

import { User } from 'src/app/models/user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class UserService {
  db: any;
  user: any = null;
  userData: User;
  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.db = this.firestore.firestore;
    this.loadUser();
  }

  async loadUser() {
    await this.auth.onAuthStateChanged(async (user) => {
      if (typeof user !== 'undefined' && user !== null) {
        this.user = user;
        await this.getUser();
      }
    });

    return this.user;
  }

  getUser() {
    return this.db
      .collection('users')
      .doc(this.user.uid)
      .get()
      .then((user) => {
        return (this.userData = user.data());
      });
  }

  getUserById(id) {
    return this.db
      .collection('users')
      .doc(id)
      .get()
      .then((user) => {
        return user.data();
      });
  }

  /* async loadUser() {
    await firebase.auth().onAuthStateChanged(async user => {
      this.user = await this.getUser(user.uid);
    });

    return this.user
  } */

  /*   setUser() {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          this.user = user
        } else {
          // No user is signed in.
        }
      });
    } */

  public async getUserProfileInformation() {
    const user = await this.auth.currentUser;
    let name, email, photoUrl, uid, emailVerified;

    if (user != null) {
      name = user.displayName;
      email = user.email;
      photoUrl = user.photoURL;
      emailVerified = user.emailVerified;
      uid = user.uid;
    }
  }

  /*  async getUser(id) {
     return await this.db.collection("users")
       .doc(id).get().then(user => {
 
         return user.data()
       })
   } */

  async setUser(user: User) {
    return this.db
      .collection('users')
      .doc(user.id)
      .set({ ...user });
  }

  async getUserByCustomerId(id){
    const snapshot = await this.db.collection("users")
    .where("billing.stripeCustomerId", "==", id)
    .get();

  return snapshot.docs[0].data();
  }

  isLoggedIn() {
    return new Promise<any>((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (user && user !== null) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public async verificationUserEmail(): Promise<void> {
    const user = await this.auth.currentUser;
    return user.sendEmailVerification().then(
      () => {
        // Email sent.
      },
      (error) => {
        // An error happened.
      }
    );
  }

  public async sendUserPasswordResetEmail(): Promise<void> {
    const user = await this.auth.currentUser;

    return this.auth.sendPasswordResetEmail(user.email).then(
      () => {
        // Email sent.
      },
      (error) => {
        // An error happened.
      }
    );
  }

  async deleteUser(userId) {
    await this.db.collection('users').doc(userId).delete();
  }
}
