import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Board } from 'src/app/models/board.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';
import { Post } from '../../models/post.model';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AddPostComponent implements OnInit {
  @Input() showAddFeature: boolean;
  @Input() boardId: string;
  @Output() updateAddFeatureState = new EventEmitter<boolean>();
  tags: Tag[] = [];
  postForm: FormGroup;
  board: Board;
  selectedTags: Tag[] = [];
  postAnonymously: boolean = false;
  images:string[] = []
  constructor(
    private fb: FormBuilder,
    private postService: PostService,
    private userService: UserService,
    private boardService: BoardService,
    private tagService: TagService,
  ) {
    this.postForm = this.fb.group(
      {
        title: ['', Validators.required],
        description: [''],
      },
      { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {
    this.loadTags();
  }


  async loadTags() {
    this.tags = [];

    this.board = await this.boardService.getBoard(this.boardId);
    this.board.tags.forEach(async (tagId) => {
      this.tags.push(await this.tagService.getTag(tagId));
    });
  }

  get stateName() {
    return this.showAddFeature ? 'show' : 'hide';
  }

  toggle() {
    this.showAddFeature = !this.showAddFeature;
    this.updateAddFeatureState.emit(this.showAddFeature);
  }

  close(){
    this.showAddFeature = !this.showAddFeature;
    this.updateAddFeatureState.emit(true);
  }

  async addPost() {
    if (!this.postForm.valid) return;

    let currentUser: User = await this.userService.getUser();

    var tagIds = this.selectedTags.map((tag) => {
      return tag.id;
    });

    const post: Post = {
      boardId: this.boardId,
      comments: [],
      date_created: new Date(),
      date_updated: new Date(),
      description: this.postForm.controls['description'].value,
      roadmapId: '',
      tags: tagIds,
      title: this.postForm.controls['title'].value,
      upvotes: [currentUser.id],
      creator: this.postAnonymously ? null : currentUser.id,
      status: this.postService.statusList.CREATED,
      changelog:'',
      attachments: this.images
    };

    const postId = await this.postService.createPost(post);

    let board: Board = await this.boardService.getBoard(this.boardId);
    board.posts.push(postId);
    await this.boardService.setBoard(board);

    for (let tag of this.selectedTags) {
      tag.featureRequests.push(postId);
      await this.tagService.setTag(tag);
    }

    currentUser.upvotes.push(postId);
    currentUser.createdPosts.push(postId);
    await this.userService.setUser(currentUser);
    this.postForm.reset();
    this.toggle();
  }

  addTag(tag: Tag) {
    const isTagSelected = this.selectedTags.find(
      (selectedTag) => selectedTag.id === tag.id
    );

    if (isTagSelected) {
      this.selectedTags = this.selectedTags.filter(
        (selectedTag) => selectedTag.id !== tag.id
      );
    } else {
      this.selectedTags.push(tag);
    }
  }

  getTagStyle(tag: Tag) {
    const isTagSelected = this.selectedTags.find(
      (selectedTag) => selectedTag.id === tag.id
    );
    if (!isTagSelected) {
      return { 'background-color': tag.color + '80' };
    } else {
      return { 'background-color': tag.color };
    }
  }

  async upload(event) {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    // this.ref = this.afStorage.ref(randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    //this.task = this.ref.put(event.target.files[0]);
    const picture = await this.postService.uploadPostImage(
      randomId,
      event.target.files[0]
    );
    this.images.push(await picture.ref.getDownloadURL());
  }
}
