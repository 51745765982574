<app-add-board
  (closeModal)="closeModal()"
  [projectId]="projectId"
  *ngIf="showAddBoardModal"
></app-add-board>

<app-project-settings
  (closeSettingsModal)="closeSettingsModal()"
  *ngIf="showProjectSettings"
  [project]="project"
></app-project-settings>

<div class="flex flex-no-wrap">
  <app-sidebar *ngIf="projectId" [projectId]="projectId" [boards]="boards"></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
    <div class="flex items-center justify-between mt-10 mb-5">
      <h1 class="text-5xl font-bold text-gray-900" *ngIf="project">Boards</h1>

      <div
        (click)="add()"
        class="cursor-pointer w-10 h-10 bg-fb-red text-white rounded-full flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-plus"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
    </div>

    <!--   <div class="w-full xl:mx-0 h-12 hidden sm:block bg-white shadow rounded">
      <ul class="flex px-5">
        <li
          (click)="this.active_status = 1"
          [ngClass]="
            active_status == 1
              ? 'text-sm border-indigo-700 pt-3 rounded-t text-indigo-700 mr-12'
              : 'text-sm text-gray-600 py-3 flex items-center mr-12 hover:text-indigo-700 cursor-pointer'
          "
        >
          <div class="flex items-center mb-3">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-home"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="5 12 3 12 12 3 21 12 19 12" />
                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
              </svg>
            </span>
            <span class="ml-1 font-normal">Boards</span>
          </div>
          <div
            *ngIf="active_status == 1"
            class="w-full h-1 bg-indigo-700 rounded-t-md"
          ></div>
        </li>
        <li
          (click)="this.active_status = 2"
          [ngClass]="
            active_status == 2
              ? 'text-sm border-indigo-700 pt-3 rounded-t text-indigo-700 mr-12'
              : 'text-sm text-gray-600 py-3 flex items-center mr-12 hover:text-indigo-700 cursor-pointer'
          "
        >
          <div class="flex items-center mb-3">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-eye"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx="12" cy="12" r="2" />
                <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />
                <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" />
              </svg>
            </span>
            <span class="ml-1 font-normal">Changelog</span>
          </div>
          <div
            *ngIf="active_status == 2"
            class="w-full h-1 bg-indigo-700 rounded-t-md"
          ></div>
        </li>
        <li
          (click)="this.active_status = 3"
          [ngClass]="
            active_status == 3
              ? 'text-sm border-indigo-700 pt-3 rounded-t text-indigo-700 mr-12'
              : 'text-sm text-gray-600 py-3 flex items-center mr-12 hover:text-indigo-700 cursor-pointer'
          "
        >
          <div class="flex items-center mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-eye"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <circle cx="12" cy="12" r="2" />
              <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />
              <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" />
            </svg>
            <span class="ml-1 font-normal">Settings</span>
          </div>
          <div
            *ngIf="active_status == 3"
            class="w-full h-1 bg-indigo-700 rounded-t-md"
          ></div>
        </li>
      </ul>
    </div> -->

    <div
      *ngIf="active_status === 1"
      class="grid sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3"
    >
      <div
        *ngFor="let board of boards"
        class="mx-auto container py-4 px-6 -ml-6"
      >
        <div class="w-full">
          <div
            (click)="openBoard(board.id)"
            class="w-full cursor-pointer bg-white hover:shadow-xl rounded-lg shadow-lg mb-3 py-5 px-4"
          >
            <div class="flex flex-row justify-between">
              <h4 class="text-gray-800 font-semibold mb-4">{{ board.name }}</h4>
              <div class="flex items-center flex-no-wrap">
                <div class="w-12 h-12 bg-cover bg-center rounded-md">
                  <img
                    [src]="
                      board.settings.logo
                        ? board.settings.logo
                        : 'assets/fb-logo-badge.png'
                    "
                    alt=""
                    class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white shadow"
                  />
                </div>
              </div>
            </div>

            <p class="mb-3 text-gray-600 text-sm">{{ board.description }}</p>
            <div class="flex items-center justify-between text-gray-600">
              <div
                class="mb-5 rounded-full border border-gray-400 py-1 pl-2 pr-3 flex items-center"
              >
                <p class="ml-2 text-xs">{{ board.posts.length }} Posts</p>
              </div>
            </div>
            <div class="mt-4 flex justify-between items-center text-gray-600">
              <!-- <div class="flex items-center flex-no-wrap">
                                <div class="w-6 h-6 bg-cover bg-center rounded-md">
                                    <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/avatars/a_4_0.png" alt="" class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white shadow" />
                                </div>
                                <div class="w-6 h-6 bg-cover rounded-md -ml-2">
                                    <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/avatars/a_4_1.png" alt="" class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white shadow" />
                                </div>
                                <div class="w-6 h-6 bg-cover rounded-md bg-center -ml-2">
                                    <img src="https://tuk-cdn.s3.amazonaws.com/assets/components/avatars/a_4_2.png" alt="" class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white shadow" />
                                </div>
                            </div> -->
              <div>
                <p class="text-gray-400 text-sm">
                  {{ board.registeredUsers.length }} Registered Users
                </p>
              </div>

              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z"></path>
                                <circle cx="5" cy="12" r="1"></circle>
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="19" cy="12" r="1"></circle>
                            </svg> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
