<app-add-tag
  (closeModal)="closeTagModal()"
  [boardId]="boardId"
  *ngIf="showAddTag"
></app-add-tag>

<app-add-project
  (closeModal)="closeProjectModal($event)"
  *ngIf="showAddProjectModal"
></app-add-project>

<!-- Navbar Ends -->
<div
  (click)="openSidebar(false)"
  class="flex items-center justify-center rounded-r-md bg-gray-800 text-gray-300 ml-0 cursor-pointer absolute inset-0 mt-10 m-auto w-8 h-8"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-chevron-right"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="9 6 15 12 9 18" />
  </svg>
</div>
<div
  id="sidebar"
  class="overflow-y-scroll invisible lg:visible lg:overflow-y-auto fixed lg:sticky h-screen lg:h-screen z-40 top-0 bg-gray-900 pt-10 w-64"
>
  <div class="px-8">
    <div class="flex items-center justify-between cursor-pointer">
      <div class="w-32">
        <img
          class="w-full"
          src="assets/fb-white-small.png"
          alt="quicklist logo"
        />
      </div>
      <div
        (click)="openSidebar(true)"
        class="text-gray-700 ml-8 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-left"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="15 6 9 12 15 18" />
        </svg>
      </div>
    </div>
    <ul class="my-10 flex flex-wrap">
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/board-list/' + projectId]"
          [ngClass]="
            active.includes('/board') ||
            active.includes('/post') ||
            active === '/'
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="12 4 4 8 12 12 20 8 12 4" />
            <polyline points="4 12 12 16 20 12" />
            <polyline points="4 16 12 20 20 16" />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Boards</p>
        </a>
      </li>
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/changelog/' + projectId]"
          [ngClass]="
            active.includes('/changelog')
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
            />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Changelog</p>
        </a>
      </li>
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/roadmap/' + this.projectId]"
          [ngClass]="
            active.includes('/roadmap')
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Roadmap</p>
        </a>
      </li>
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/scoring/' + this.projectId]"
          [ngClass]="
            active.includes('/scoring')
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
            />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Scoring</p>
        </a>
      </li>
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/settings/' + projectId]"
          [ngClass]="
            active.includes('/settings')
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Settings</p>
        </a>
      </li>
    </ul>

    <div
      *ngIf="active.includes('/board/') || active.includes('/tag/')"
      class="my-20"
    >
      <div class="flex items-center justify-between text-gray-600">
        <h4 class="uppercase font-semibold">Tags</h4>
        <svg
          (click)="showAddTag = true"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer icon icon-tabler icon-tabler-plus"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
      <ul class="text-gray-600 mt-8">
        <li
          (click)="openTag(tag.id)"
          class="mb-5 flex items-center cursor-pointer"
          *ngFor="let tag of tags"
        >
          <span
            class="mr-2 w-2 h-2 rounded-full"
            [ngStyle]="{ 'background-color': tag.color }"
          ></span>
          {{ tag.name }}
        </li>
      </ul>
    </div>
  </div>
  <!-- Code block starts -->
  <div
    *ngIf="currentProject"
    class="px-8 py-4 w-full flex border-gray-800 mt-20 items-center text-gray-600 text-xs"
  >
    <div class="relative w-full">
      <div class="flex items-center justify-between space-x-3">
        <div
          (click)="showProjectMenu = !showProjectMenu"
          class="flex items-center justify-between rounded w-full border border-gray-600 cursor-pointer"
          data-menu
        >
          <div class="flex">
            <p
              class="pl-3 py-3 text-gray-600 text-xs leading-3 tracking-normal font-normal"
            >
              Project:
            </p>
            <p
              class="pl-3 py-3 text-white text-xs leading-3 tracking-normal font-normal"
            >
              {{ currentProject?.name }}
            </p>
          </div>
          <div
            *ngIf="projects.length > 1"
            class="cursor-pointer text-white mr-3"
          >
            <svg
              *ngIf="showProjectMenu"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-up"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 15 12 9 18 15" />
            </svg>
            <svg
              *ngIf="!showProjectMenu"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-up"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        </div>
        <svg
          (click)="showAddProjectModal = true"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer icon icon-tabler icon-tabler-plus"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
      <ul
        *ngIf="showProjectMenu"
        class="visible transition duration-300 opacity-100 ease-out bg-white dark:bg-gray-800 shadow rounded mt-2 py-1 w-48 absolute"
      >
        <li
          *ngFor="let project of projects"
          (click)="openProject(project)"
          class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:text-white text-xs leading-3 tracking-normal py-3 hover:bg-gray-600 hover:text-white px-3 font-normal"
        >
          {{ project.name }}
        </li>
      </ul>
    </div>
    <!-- Code block ends -->
  </div>
  <div
    (click)="openUserSettings()"
    class="px-8 py-4 w-full flex border-t border-gray-800 items-center text-gray-600 uppercase text-xs"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer icon icon-tabler icon-tabler-trash"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <p class="cursor-pointer pl-2">Account</p>
  </div>
  <div
    (click)="logout()"
    class="px-8 py-4 w-full flex items-center text-gray-600 uppercase text-xs"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer icon icon-tabler icon-tabler-trash"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>
    <p class="cursor-pointer pl-2">Logout</p>
  </div>
</div>
<!-- Navbar Ends -->
