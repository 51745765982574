import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnChanges {
  active: string = '';
  @Input() boards: Board[];
  @Input() boardId: string;
  @Input() projectId: string;
  board: Board;
  showAddTag: boolean = false;
  tags: Tag[] = [];
  projects: Project[] = [];
  currentProject: Project;
  showProjectMenu: boolean = false;
  showAddProjectModal: boolean = false;
  user: User;
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private tagService: TagService,
    private boardService: BoardService,
    private userService: UserService,
    private projectService: ProjectService
  ) {
    this.active = router.url;
  }

  async loadProjects() {
    this.projects = [];

    for (let projectId of this.user.projects) {
      this.projects.push(await this.projectService.getProject(projectId));
    }

    if (this.projects.length < 1) {
      this.showAddProjectModal = true;
    }

    this.currentProject = this.projects.find((p) => p.id === this.projectId);
  }

  async ngOnInit() {
    let currentProject = await this.projectService.getProject(this.projectId);

    this.user = await this.userService.getUserById(currentProject.owner);

    this.loadProjects();
    this.loadTags();
  }

  openProject(project) {
    this.currentProject = project;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/board-list/' + project.id]);
  }

  ngOnChanges() {
    this.loadTags();
  }

  async loadTags() {
    if (this.active.includes('/board/') || this.active.includes('/tag/')) {
      let urlId = this.route.snapshot.paramMap.get('id');
      if (this.active.includes('/tag/')) {
        const tag: Tag = await this.tagService.getTag(
          this.route.snapshot.paramMap.get('id')
        );
        urlId = tag.boardId;
      }
      this.boardId = urlId;

      this.board = await this.boardService.getBoard(this.boardId);
      this.tags = [];

      this.board.tags.forEach(async (tagId) => {
        this.tags.push(await this.tagService.getTag(tagId));
      });
    }
  }

  public openSidebar = (flag) => {
    let sidebar = document.getElementById('sidebar');
    flag ? sidebar.classList.add('hidden') : sidebar.classList.remove('hidden'),
      sidebar.classList.remove('invisible');
  };

  openBoard(boardId) {
    this.router.navigate(['/board/' + boardId]);
  }

  async logout() {
    await this.authService.signOut();
    this.router.navigate(['/login']);
  }

  openUserSettings() {
    this, this.router.navigate(['/user-settings']);
  }

  closeTagModal() {
    this.loadTags();
    this.showAddTag = false;
  }

  closeProjectModal(projectId) {
    this.showAddProjectModal = false;
    if (projectId) {
      this.router.navigate(['/board-list/' + projectId]);
    }

    if (this.projects.length < 1) {
      this.showAddProjectModal = true;
    }
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }
}
