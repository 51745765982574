import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Board } from 'src/app/models/board.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-board',
  templateUrl: './add-board.component.html',
  styleUrls: ['./add-board.component.css'],
})
export class AddBoardComponent implements OnInit {
  @Input() projectId: string;
  @Output() closeModal = new EventEmitter();
  boardForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService
  ) {
    this.createForm();
  }
  createForm() {
    this.boardForm = this.fb.group(
      {
        name: ['', Validators.required],
        description: [''],
      },
      { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {}

  async addBoard() {
    if (!this.boardForm.valid) return;
    let currentUser: User = await this.userService.getUser();

    const board: Board = {
      admins: [currentUser.id],
      name: this.boardForm.controls['name'].value,
      description: this.boardForm.controls['description'].value,
      projectID: this.projectId,
      settings: {
        link: '',
        visibility: true,
        mandatoryRegistration: true,
        logo: '',
        fbLogoVisibility: true,
        disablePost: false,
        notificationNewPost: true,
        notificationNewUpvote: true,
        notificationNewUser: true
      },
      posts: [],
      registeredUsers: [currentUser.id],
      tags: [],
    };

    const boardId = await this.boardService.createBoard(board);
    const project = await this.projectService.getProject(this.projectId);

    project.boards.push(boardId);
    currentUser.boards.push(boardId);

    await this.userService.setUser(currentUser);
    await this.projectService.setProject(project);
    this.close();
  }

  close() {
    this.closeModal.emit(true);
  }
}
