<div class="flex flex-no-wrap">
  <app-sidebar [projectId]="projectId"></app-sidebar>
  <div class="py-8 px-12 w-full">
    <div class="flex justify-between">
      <div
        (click)="back()"
        class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
      >
        <svg
          class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">
          back
        </p>
      </div>
    </div>

    <div class="container mx-auto shadow bg-white dark:bg-gray-800 rounded">
      <div class="xl:w-full border-b border-gray-300 dark:border-gray-700 py-5">
        <div class="flex items-center w-11/12 mx-auto">
          <p class="text-lg text-gray-800 dark:text-gray-100 font-bold">
            Add Roadmap Item
          </p>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div
          class="xl:w-9/12 mx-auto md:flex-row flex-col space-y-5 md:space-y-0 justify-between mt-10 flex xl:mx-0"
        >
          <div class="flex flex-col xl:w-2/6 w-full">
            <label
              for="title"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Title</label
            >
            <input
              type="text"
              id="title"
              name="title"
              [(ngModel)]="title"
              class="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800 bg-transparent dark:text-gray-100"
              placeholder="Performance Improvements"
              required
            />
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Enter a start & end date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                [(ngModel)]="startDate"
                matStartDate
                placeholder="Start date"
              />
              <input [(ngModel)]="endDate" matEndDate placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div
          class="mx-auto space-y-5 md:space-y-0 justify-between mt-10 flex xl:mx-0"
        >
          <div class="flex flex-col xl:w-3/6 w-full">
            <label
              for="description"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Description (optional)</label
            >
            <textarea
              rows="4"
              id="description"
              name="description"
              [(ngModel)]="description"
              class="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800 bg-transparent dark:text-gray-100"
              placeholder="Performance Improvements"
              required
            ></textarea>
          </div>
        </div>

        <div class="w-full xl:mx-0">
          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Link Posts to this Changlog</label
            >
            <button
              (click)="openSelectPosts()"
              class="my-2 bg-white transition duration-150 ease-in-out hover:border-indigo-600 hover:text-indigo-600 rounded border border-indigo-700 text-indigo-700 px-6 py-2 text-sm"
            >
              Add Post
            </button>
          </div>
          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              *ngIf="posts.length > 0"
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Linked Posts</label
            >

            <div class="flex flex-col">
              <div
                class="ml-2 m-2 flex space-x-6 items-center"
                *ngFor="let post of posts"
              >
                <p>{{ post.title }}</p>
                <div class="ml-2 flex items-center">
                  <span class="text-xs text-gray-500 font-normal">{{
                    post.status
                  }}</span>
                </div>

                <svg
                  (click)="removePost(post)"
                  class="h-4 w-4 text-fb-red"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full py-4 px-4 bg-gray-100 dark:bg-gray-700 mt-6 flex justify-end rounded-bl rounded-br"
      >
        <button
          (click)="save()"
          class="bg-indigo-600 text-white transition duration-150 ease-in-out hover:border-indigo-600 hover:text-indigo-600 rounded border border-indigo-700 px-8 py-2 text-sm focus:outline-none"
        >
          Save
        </button>
      </div>
    </div>
  </div>

  <app-select-posts
    [type]="'roadmap'"
    [boards]="boards"
    (closeSelectPosts)="closeSelectPosts($event)"
    *ngIf="showPostDialog && boards"
  ></app-select-posts>
</div>
