import { Pipe, PipeTransform } from '@angular/core';
import { TagService } from '../services/tag.service';

@Pipe({
  name: 'tagColor',
})
export class TagColorPipe implements PipeTransform {
  constructor(private tagService: TagService) {}
  transform(tagId: string): Promise<string> {
    return this.tagService.getTag(tagId).then((tag) => {
      return tag.color;
    });
  }
}
