import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
})
export class ProjectComponent implements OnInit {
  showAddProjectModal: boolean = false;
  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private router: Router
  ) {}

  projects: Project[] = [];
  async ngOnInit() {
    this.loadProjects();
  }

  async loadProjects() {
    this.projects = []
    const user:User = await this.userService.getUser()

    if(user.firstLogin){
      this.router.navigate(['/onboarding'])
    }

    user.projects.forEach(async projectId =>{
      this.projects.push(await this.projectService.getProject(projectId));
    })
    
  }

  addProject() {
    this.showAddProjectModal = true;
  }

  closeModal() {
    this.loadProjects();
    this.showAddProjectModal = false;
  }

  openBoards(id) {
    this.router.navigate(['/board-list/' + id]);
  }
}
