import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexFill,
  ApexDataLabels,
  ApexYAxis,
  ApexGrid,
} from 'ng-apexcharts';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { Roadmap } from 'src/app/models/roadmap.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { RoadmapService } from 'src/app/services/roadmap.service';
import { UserService } from 'src/app/services/user.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.css'],
})
export class RoadmapComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  projectId: string;
  project: Project;
  boards: Board[] = [];
  roadmapItems: Roadmap[];
  menu: any;
  modalData = {
    header: 'Delete Roadmap Item',
    description: 'Do you want to delete this Roadmap Item?',
    yes: 'Yes',
    no: 'No',
  };
  user: User
  showModal: boolean = false;
  currentRoadmap: Roadmap;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private roadmapService: RoadmapService,
    private postService: PostService
  ) {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');

    this.user= await this.userService.getUser();

    if (this.user.firstLogin) {
      this.router.navigate(['/onboarding']);
      return;
    }

    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      this.projectId = this.user.projects[0];
    }

    if (this.user.projects.indexOf(this.projectId) < 0) {
      this.router.navigate(['/board-list' + this.projectId]);
    }

    this.loadBoards();

    this.loadRoadmaps();
  }

  async loadRoadmaps() {
    this.roadmapItems = await this.roadmapService.getRoadmapsOfProject(
      this.projectId
    );
    let chartdata = [];
    for (let ri of this.roadmapItems) {
      let chartItem = {
        x: ri.name,
        y: [
          new Date(ri.startDate['seconds'] * 1000).getTime(),
          new Date(ri.endDate['seconds'] * 1000).getTime(),
        ],
        fillColor: ri.color,
      };
      chartdata.push(chartItem);
    }

    this.chartOptions = {
      series: [
        {
          data: chartdata,
        },
      ],
      chart: {
        height: 350,
        width: '90%',
        type: 'rangeBar',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          var label = opts.w.globals.labels[opts.dataPointIndex];
          return label;
        },
        style: {
          colors: ['#f3f4f5', '#fff'],
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          colors: ['#f3f4f5', '#fff'],
          opacity: 1,
        },
      },
    };
  }

  async loadBoards() {
    this.boards = [];
    if (typeof this.projectId === 'undefined' || this.projectId === null) {
      return;
    }
    this.project = await this.projectService.getProject(this.projectId);
    this.project.boards.forEach(async (boardId) => {
      this.boards.push(await this.boardService.getBoard(boardId));
    });
    this.boardService.setBoards(this.boards);
  }

  addRoadmap() {
    this.router.navigate(['roadmap/' + this.projectId + '/add-roadmap']);
  }

  openPost(postId) {
    this.router.navigate(['/post/' + postId]);
  }

  openRoadmap(roadmap: Roadmap) {
    this.router.navigate([
      '/roadmap/' + this.projectId + '/detail/' + roadmap.id,
    ]);
  }

  deleteRoadmap(roadmap: Roadmap) {
    this.showModal = true;
    this.currentRoadmap = roadmap;
  }

  async closeModal(val: boolean) {
    if (val) {
      this.project.roadmaps = this.project.roadmaps.filter(
        (rm) => rm !== this.currentRoadmap.id
      );
      await this.projectService.setProject(this.project);

      for (let postId of this.currentRoadmap.featureRequests) {
        let post: Post = await this.postService.getPost(postId);
        post.roadmapId = '';
        await this.postService.setPost(post);
      }
      await this.roadmapService.deleteRoadmap(this.currentRoadmap.id);
      this.loadRoadmaps();
    }
    this.showModal = false;
  }
}
