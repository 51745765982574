import { Pipe, PipeTransform } from '@angular/core';
import { PostService } from '../services/post.service';

@Pipe({
  name: 'post'
})
export class PostPipe implements PipeTransform {

  constructor(private postService: PostService) {}
  transform(postId: string): Promise<string> {
    return this.postService.getPost(postId).then((post) => {
      return post.title;
    });
  }
}
