import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
})
export class OnboardingComponent implements OnInit {
  step: number = 0;
  projectForm: FormGroup;
  boardForm: FormGroup;
  postForm: FormGroup;
  currentUser: User;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private projectService: ProjectService,
    private boardService: BoardService,
    private postService: PostService
  ) {}

  async ngOnInit() {
    this.projectForm = this.fb.group(
      {
        name: ['', [Validators.required, Validators.minLength(3)]],
      },
      { updateOn: 'blur' }
    );

    this.boardForm = this.fb.group(
      {
        name: ['', [Validators.required, Validators.minLength(3)]],
      },
      { updateOn: 'blur' }
    );

    this.postForm = this.fb.group(
      {
        title: ['', [Validators.required, Validators.minLength(3)]],
      },
      { updateOn: 'blur' }
    );
  }

  async openProjects() {
    this.currentUser = await this.userService.getUser();
    this.currentUser.firstLogin = false;
    await this.userService.setUser(this.currentUser)
    this.router.navigate(['/board-list']);
  }

  async addStep() {
     this.currentUser = await this.userService.getUser();
    switch (this.step) {
      case 0: {
        if (!this.projectForm.valid) return;

        const project: Project = {
          name: this.projectForm.controls['name'].value,
          description: '',
          boards: [],
          owner: this.currentUser.id,
          changelog:[],
          roadmaps:[]
        };
        const projectId = await this.projectService.createProject(project);
        this.currentUser.projects.push(projectId);
        await this.userService.setUser(this.currentUser);
        this.step++;

        break;
      }
      case 1: {
        const board: Board = {
          admins: [this.currentUser.id],
          name: this.boardForm.controls['name'].value,
          description: '',
          projectID: this.currentUser.projects[0],
          settings: {
            link: '',
            visibility: true,
            mandatoryRegistration: true,
            logo: '',
            fbLogoVisibility: true,
            disablePost: false,
            notificationNewPost: true,
            notificationNewUpvote: true,
            notificationNewUser: true
          },
          posts: [],
          registeredUsers: [this.currentUser.id],
          tags:[]
        };

        const boardId = await this.boardService.createBoard(board);
        const project = await this.projectService.getProject(
          this.currentUser.projects[0]
        );

        project.boards.push(boardId);
        this.currentUser.boards.push(boardId);

        await this.userService.setUser(this.currentUser);
        await this.projectService.setProject(project);
        this.step++;

        break;
      }
      case 2:
        const post: Post = {
          boardId: this.currentUser.boards[0],
          comments: [],
          date_created: new Date(),
          date_updated: new Date(),
          description: '',
          roadmapId: '',
          tags: [],
          title: this.postForm.controls['title'].value,
          upvotes: [this.currentUser.id],
          creator: this.currentUser.id,
          status: 'Created',
          changelog:''
        };

        const postId = await this.postService.createPost(post);

        let board: Board = await this.boardService.getBoard(
          this.currentUser.boards[0]
        );
        board.posts.push(postId);
        await this.boardService.setBoard(board);

        this.currentUser.createdPosts.push(postId);
        this.currentUser.upvotes.push(postId)
        await this.userService.setUser(this.currentUser);
        this.step++;

        break;
      case 3:
        this.currentUser.firstLogin = false;
        await this.userService.setUser(this.currentUser);
        this.step++;

        break;
    }


    if (this.step > 3) {
      this.router.navigate(['/board/' + this.currentUser.boards[0]]);
    }
  }

  copyUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://app.featurebear.com/' + this.currentUser.boards[0];
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
