import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Roadmap } from '../models/roadmap.model';

@Injectable({
  providedIn: 'root',
})
export class RoadmapService {
  constructor(private db: AngularFirestore) {}

  async getRoadmapsOfProject(projectId) {
    const snapshot = await this.db
      .collection('roadmaps')
      .ref.where('projectId', '==', projectId)
      .get();
    let roadmapItems = [];

    snapshot.forEach((roadmapItem) => {
      roadmapItems.push(roadmapItem.data());
    });

    return roadmapItems;
  }

  async getRoadmap(roadmapId) {
    const snapshot = await this.db.collection("roadmaps").ref.doc(roadmapId).get();
    const roadmap = snapshot.data() as Roadmap
    return roadmap;
  }

  async createRoadmap(roadmap: Roadmap) {
    const id = this.db.collection('roadmaps').doc().ref.id;
    roadmap.id = id;
    await this.db.collection('roadmaps').doc(id).set(roadmap);
    return id;
  }

  async setRoadmap(roadmap: Roadmap) {
    return this.db
      .collection('roadmaps')
      .doc(roadmap.id)
      .set({ ...roadmap });
  }

  async deleteRoadmap(roadmapId) {
    await this.db.collection('roadmaps').doc(roadmapId).delete();
  }
}
