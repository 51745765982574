import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Changelog } from 'src/app/models/changelog.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { ChangelogService } from 'src/app/services/changlog.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-changelog-detail',
  templateUrl: './changelog-detail.component.html',
  styleUrls: ['./changelog-detail.component.css'],
})
export class ChangelogDetailComponent implements OnInit {
  user: User;
  project: Project;
  changelogId: string;
  boards: Board[];
  htmlstring: string = '<br><br><br><br><br><br>';
  showPostDialog: boolean = false;
  posts: Post[] = [];
  title: string = '';
  status: boolean = false;
  changelog: Changelog;
  showDeleteChangelog: boolean = false;
  showNotificationMenu: boolean = false;
  notification: any = { type: 'none', text: 'None' };
  showCheck: boolean = false;
  showModal: boolean = false;
  modalData = {
    header: 'Status of the Posts',
    description: 'Do you want to set the status of the selected posts to Done?',
    yes: 'Yes',
    no: 'No',
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private postService: PostService,
    private changelogService: ChangelogService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.changelogId = this.route.snapshot.paramMap.get('id');
    this.changelog = await this.changelogService.getChangelog(this.changelogId);
    this.project = await this.projectService.getProject(
      this.changelog.projectId
    );
    this.user = await this.userService.getUser();
    this.boards = this.boardService.getBoards();

    if (typeof this.boards === 'undefined') {
      this.boards = [];

      this.project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }
    if (this.project.owner.indexOf(this.user.id) < 0) {
      this.router.navigate(['/project']);
    }

    this.loadPosts();
  }

  async loadPosts() {
    for (let postId of this.changelog.posts) {
      this.posts.push(await this.postService.getPost(postId));
    }
  }

  async save(showModal: boolean) {
    let postIds = [];
    this.posts.forEach((post) => {
      postIds.push(post.id);
    });

    let difference = this.changelog.posts.filter((x) => !postIds.includes(x));
    for (let postId of difference) {
      let post = await this.postService.getPost(postId);
      post.changelog = '';
      await this.postService.setPost(post);
    }

    this.changelog.posts = postIds;

    await this.changelogService.setChangelog(this.changelog);

    if (showModal) {
      this.showModal = true;
    }
  }

  openSelectPosts() {
    this.showPostDialog = true;
  }

  removePost(post) {
    this.posts = this.posts.filter((p) => p.id !== post.id);
    this.save(false);
  }

  closeSelectPosts(posts: Post[]) {
    this.posts = posts;
    if (posts.length > 0) {
      this.changelog.text +=
        '<h3>🚀 The following requests were implemented in this release:</h3> <br>';

      posts.forEach((post) => {
        this.changelog.text +=
          '✅ <a href=https://app.featurebear.com/post/' +
          post.id +
          '>' +
          post.title +
          '</a> <br>';
      });
    }
    this.showPostDialog = false;
    this.save(true);
  }

  getChangelogURL() {
    return (
      'https://app.featurebear.com/' +
      this.boards[0]?.id +
      '/changelog/' +
      this.changelog.id
    );
  }

  async deleteChangelog() {
    for (let postId of this.changelog.posts) {
      let post = await this.postService.getPost(postId);
      post.changelog = '';
      await this.postService.setPost(post);
    }
    this.project.changelog = this.project.changelog.filter(
      (pc) => pc !== this.changelog.id
    );
    await this.projectService.setProject(this.project);
    await this.changelogService.deleteChangelog(this.changelogId);
    this.router.navigate(['/board-list/' + this.project.id]);
  }

  async closeModal(val: boolean) {
    if (val) {
      for (let post of this.posts) {
        post.changelog = this.changelog.id;
        post.status = 'Done';
        await this.postService.setPost(post);
      }
    }
    this.showModal = false;
  }

  setNotification(type: string) {
    switch (type) {
      case 'none':
        this.notification = { type: 'none', text: 'None' };
        break;
      case 'commented':
        this.notification = {
          type: 'commented',
          text: 'Who commented on a post',
        };

        break;
      case 'upvoted':
        this.notification = { type: 'upvoted', text: 'Who upvoted a post' };

        break;
      case 'commentedUpvoted':
        this.notification = {
          type: 'commentedUpvoted',
          text: 'Who commented or upvoted a post',
        };

        break;
      case 'all':
        this.notification = { type: 'all', text: 'All registered Users' };

        break;
    }
    this.showNotificationMenu = false;
  }

  back() {
    this.router.navigate(['/board-list/' + this.project.id]);
  }

  notifyUser() {
    this.http
      .post(environment.nodeUrl + 'notification/new-changelog', {
        changelog: this.changelog,
        posts: this.posts,
        boards: this.boards,
        project: this.project,
        notification: this.notification,
        currentUser: this.user,
      })
      .subscribe((res) => {
        this.showCheck = true;
      });
  }
}
