<app-login-dialog
  (closeModal)="closeLoginModal()"
  *ngIf="showLoginModal"
></app-login-dialog>

<div class="flex flex-no-wrap">
  <app-sidebar *ngIf="user" [projectId]="user?.projects[0]"></app-sidebar>
  <div class="w-full">
    <app-upgrade
      [user]="user"
      *ngIf="user?.billing?.tier === 'FREE' || !user?.billing"
    ></app-upgrade>
    <div class="py-8 px-12 w-full">
      <div
        class="xl:w-5/12 mx-auto sm:mx-0 sm:w-6/12 md:w-6/12 w-10/12 bg-white shadow-lg rounded flex pr-4 absolute left-0 sm:left-auto right-0 sm:top-0 sm:mr-6 mt-16 sm:mt-6 mb-6 sm:mb-0 hidden transition duration-150 ease-in-out"
        id="notification"
      >
        <div
          role="alert"
          aria-label="Close"
          class="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
          (click)="closeModal()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <div
          class="px-5 border-r border-gray-300 flex items-center justify-center text-green-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="30"
            height="30"
            fill="currentColor"
          >
            <path
              class="heroicon-ui"
              d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
            ></path>
          </svg>
        </div>
        <div class="flex flex-col justify-center pl-4 py-4">
          <p class="text-sm text-gray-800 font-semibold">Successful Saved</p>
          <p class="text-xs text-gray-600 font-normal">
            You have successfully changed your user settings.
          </p>
        </div>
      </div>

      <form id="form">
        <div class="container mx-auto shadow bg-white rounded">
          <div class="xl:w-full border-b border-gray-300 py-5">
            <div class="flex items-center w-11/12 mx-auto">
              <p class="text-lg text-gray-800 font-bold">User Settings</p>
            </div>
          </div>

          <div class="w-11/12 mx-auto" *ngIf="user">
            <div class="xl:w-9/12 mx-auto xl:mx-0">
              <div class="mt-16 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                <label
                  for="firstname"
                  class="pb-2 text-sm font-bold text-gray-800"
                  >Firstname</label
                >
                <input
                  type="text"
                  id="firstname"
                  [(ngModel)]="user.firstname"
                  name="firstname"
                  class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                  required
                />
              </div>
              <div class="mt-8 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                <label
                  for="surname"
                  class="pb-2 text-sm font-bold text-gray-800"
                  >Surname</label
                >
                <input
                  type="text"
                  id="surname"
                  [(ngModel)]="user.surname"
                  name="surname"
                  class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                  required
                />
              </div>
              <div class="mt-8 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                <label for="email" class="pb-2 text-sm font-bold text-gray-800"
                  >Email</label
                >
                <input
                  type="email"
                  disabled
                  [ngModel]="user?.email"
                  id="email"
                  name="email"
                  class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                  required
                />
              </div>

              <div class="mt-8 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                <label for="email" class="pb-2 text-sm font-bold text-gray-800"
                  >Plan</label
                >
                <input
                  type="text"
                  disabled
                  [ngModel]="user?.billing?.tier"
                  id="tier"
                  name="tier"
                  class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                />
                <div class="flex text-xs text-gray-500 mt-2">
                  You have any questions about your plan?
                  <div class="underline text-indigo-400">
                    mail@featurebear.com
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full py-4 sm:px-12 mt-6 flex justify-between rounded-bl rounded-br"
          >
            <button
              class="my-2 -ml-2 text-white w-24 flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 p-4 rounded py-2 text-sm cursor-pointer"
              (click)="save()"
            >
              Save
            </button>
            <ul
              class="flex flex-col md:flex-row md:items-center text-gray-600 text-sm"
            >
              <li class="flex items-center md:mt-0">
                <a
                  *ngIf="!showDeleteUser"
                  (click)="showDeleteUser = true"
                  class="my-2 text-white flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 mx-2 p-4 rounded py-2 text-sm cursor-pointer"
                >
                  <span class="h-4 w-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </span>
                  Delete Account
                </a>
              </li>
            </ul>
          </div>
          <div
            *ngIf="showDeleteUser"
            class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
          >
            <div class="flex pb-3 items-center">
              <div class="-ml-1 text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trash"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </div>
              <p class="text-lg text-gray-800 font-semibold pl-2">
                Delete your Account?
              </p>
            </div>
            <p class="text-sm text-gray-600 pb-3 font-normal">
              Do you really want to delete your account and all your
              boards/requests??
            </p>
            <button
              (click)="deleteUser()"
              class="focus:outline-none bg-red-400 transition duration-150 ease-in-out rounded text-white px-3 py-2 text-xs"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
