import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private db: AngularFirestore) {}


  async getTag(tagId) {
    const snapshot = await this.db.collection('tags').ref.doc(tagId).get();
    const post = snapshot.data() as Tag;
    return post;
  }

  async createTag(tag: Tag) {
    const id = this.db.collection('tags').doc().ref.id;
    tag.id = id;
    await this.db.collection('tags').doc(id).set(tag);
    return id;
  }

  async setTag(tag: Tag) {
    return this.db
      .collection('tags')
      .doc(tag.id)
      .set({ ...tag });
  }

  async deleteTag(tagId){
    await this.db.collection("tags").doc(tagId).delete();
  }
}
