import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { BoardService } from 'src/app/services/board.service';
import { Changelog } from 'src/app/models/changelog.model';

import { Post } from 'src/app/models/post.model';
import { ChangelogService } from 'src/app/services/changlog.service';
import { PostService } from 'src/app/services/post.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { not } from '@angular/compiler/src/output/output_ast';
declare var $: any;
//import * as $ from 'jquery';

@Component({
  selector: 'app-add-changelog',
  templateUrl: './add-changelog.component.html',
  styleUrls: ['./add-changelog.component.css'],
})
export class AddChangelogComponent implements OnInit, AfterViewInit {
  user: User;
  project: Project;
  projectId: string;
  boards: Board[];
  htmlstring: string = '<br><br><br><br><br><br>';
  showPostDialog: boolean = false;
  posts: Post[] = [];
  title: string = '';
  status: boolean = false;
  showNotificationMenu: boolean = false;
  notification: any = { type: 'none', text: 'None' };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService,
    private postService: PostService,
    private changelogService: ChangelogService,
    private http: HttpClient
  ) {}

  ngAfterViewInit() {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.project = await this.projectService.getProject(this.projectId);
    this.user = await this.userService.getUser();
    this.boards = this.boardService.getBoards();

    if (typeof this.boards === 'undefined') {
      this.boards = [];

      this.project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }
    if (this.project.owner.indexOf(this.user.id) < 0) {
      this.router.navigate(['/changelog/' + this.projectId]);
    }
  }

  async save() {
    let postIds = [];
    this.posts.forEach((post) => {
      postIds.push(post.id);
    });

    let changelog: Changelog = {
      date: new Date(),
      posts: postIds,
      projectId: this.projectId,
      status: this.status,
      text: this.htmlstring,
      title: this.title,
      id: '',
    };

    const changlogId = await this.changelogService.createChangelog(changelog);
    changelog.id = changlogId;

    for (let post of this.posts) {
      post.changelog = changlogId;
      post.status = 'Done';
      await this.postService.setPost(post);
    }

    this.project.changelog = this.project.changelog || [];

    this.project.changelog.push(changlogId);
    await this.projectService.setProject(this.project);

    if (this.notification.type !== 'none') {
      this.http.post(environment.nodeUrl + 'notification/new-changelog', {
        changelog: changelog,
        posts: this.posts,
        boards: this.boards,
        project: this.project,
        notification: this.notification,
        currentUser: this.user,
      });
    }

    this.router.navigate(['/changelog/' + this.projectId]);
  }

  openSelectPosts() {
    this.showPostDialog = true;
  }

  removePost(post) {
    this.posts = this.posts.filter((p) => p.id !== post.id);
  }

  closeSelectPosts(posts: Post[]) {
    this.posts = posts;
    if (posts.length > 0) {
      this.htmlstring +=
        '<h3>🚀 The following requests were implemented in this release:</h3> <br>';

      posts.forEach((post) => {
        this.htmlstring +=
          '✅ <a href=https://app.featurebear.com/post/' +
          post.id +
          '>' +
          post.title +
          '</a> <br>';
      });
    }

    this.showPostDialog = false;
  }

  setNotification(type: string) {
    switch (type) {
      case 'none':
        this.notification = { type: 'none', text: 'None' };
        break;
      case 'commented':
        this.notification = {
          type: 'commented',
          text: 'Who commented on a post',
        };

        break;
      case 'upvoted':
        this.notification = { type: 'upvoted', text: 'Who upvoted a post' };

        break;
      case 'commentedUpvoted':
        this.notification = {
          type: 'commentedUpvoted',
          text: 'Who commented or upvoted a post',
        };

        break;
      case 'all':
        this.notification = { type: 'all', text: 'All registered Users' };

        break;
    }
    this.showNotificationMenu = false;
  }

  back() {
    this.router.navigate(['/changelog/' + this.projectId]);
  }
}
