import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Comment } from '../models/comment.model';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private db: AngularFirestore) {}

  async getComment(commentId) {
    const snapshot = await this.db.collection('comments').ref.doc(commentId).get();
    const comment = snapshot.data() as Comment;
    return comment;
  }

  async createComment(comment: Comment) {
    const id = this.db.collection('comments').doc().ref.id;
    comment.id = id;
    await this.db.collection('comments').doc(id).set(comment);
    return id;
  }

  async setComment(comment: Comment) {
    return this.db
      .collection('comments')
      .doc(comment.id)
      .set({ ...comment });
  }

  async deleteComment(commentId){
    await this.db.collection("comments").doc(commentId).delete();
  }
}
