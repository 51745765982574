import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Changelog } from 'src/app/models/changelog.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ChangelogService } from 'src/app/services/changlog.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-changelog-list',
  templateUrl: './changelog-list.component.html',
  styleUrls: ['./changelog-list.component.css'],
})
export class ChangelogListComponent implements OnInit {
  changelogs: Changelog[] = [];
  //@Input() project: Project;
  projectId: string;
  project: Project;
  loader: boolean = false;
  user: User;
  constructor(
    private changelogService: ChangelogService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id');
  }

  async ngOnInit() {
    this.loader = true;
    this.user = await this.userService.getUser();

    this.project = await this.projectService.getProject(this.projectId);

    this.project.changelog = this.project.changelog || [];

    for (let changelogId of this.project.changelog) {
      this.changelogs.push(
        await this.changelogService.getChangelog(changelogId)
      );
    }
    this.changelogs.reverse();
    this.loader = false;
  }

  openChangelog(changelog: Changelog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/changelog-detail/' + changelog.id]);
  }
}
