import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tag } from 'src/app/models/tag.model';
import { BoardService } from 'src/app/services/board.service';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css'],
})
export class AddTagComponent implements OnInit {
  tagForm: FormGroup;
  @Input() boardId: string;
  @Output() closeModal = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private tagService: TagService,
    private boardService: BoardService
  ) {
    this.tagForm = this.fb.group(
      {
        name: ['', Validators.required],
        color: ['#ff7070'],
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit(): void {}

  close() {
    this.closeModal.emit(true);
  }

  async addTag() {
    if (!this.tagForm.valid) return;

    const tag: Tag = {
      name: this.tagForm.controls['name'].value,
      boardId: this.boardId,
      color: this.tagForm.controls['color'].value,
      featureRequests: [],
    };

    const tagId = await this.tagService.createTag(tag);

    let board = await this.boardService.getBoard(this.boardId);
    board.tags.push(tagId);
    await this.boardService.setBoard(board);

    this.close();
  }
}
