<div
  id="member-modal"
  class="fixed z-10 pt-32 left-0 top-0 w-full h-full overflow-auto shadow-lg display: none;"
  style="background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.4)"
>
  <div class="flex items-center justify-center py-8 px-4">
    <div
      class="md:w-80 rounded shadow-lg p-6 dark:bg-gray-800 bg-white relative"
    >
      <div
        class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 dark:text-gray-100 text-gray-400 hover: dark:text-gray-100 text-gray-600 transition duration-150 ease-in-out"
        (click)="close(false)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Close"
          class="icon icon-tabler icon-tabler-x"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <h1 class="dark:text-gray-100 text-gray-800 font-bold text-lg">
        {{ data?.header }}
      </h1>

      <p class="text-sm mt-6 font-medium dark:text-gray-100 text-gray-600">
        {{ data?.description }}
      </p>
      <div class="sm:flex items-center justify-between pt-6">
        <button
          (click)="close(false)"
          class="py-3.5 w-full dark:text-gray-100 text-gray-600 leading-3 focus:outline-none hover:opacity-90 text-sm font-semibold border-gray-600 rounded border"
        >
          {{ data?.no }}
        </button>
        <button
          (click)="close(true)"
          class="py-3.5 w-full sm:mt-0 mt-2 sm:ml-2 leading-3 text-white focus:outline-none hover:opacity-90 text-sm font-semibold border rounded border-indigo-700 bg-indigo-700"
        >
          {{ data?.yes }}
        </button>
      </div>
    </div>
  </div>
</div>
