import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-board-settings',
  templateUrl: './board-settings.component.html',
  styleUrls: ['./board-settings.component.css'],
})
export class BoardSettingsComponent implements OnInit {
  @Input() board: Board;
  @Output() closeSettingsModal = new EventEmitter();
  boardForm: FormGroup;
  showDeleteBoard: boolean = false;
  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService,
    private postService: PostService,
    private router: Router
  ) {}
  createForm() {
    this.boardForm = this.fb.group(
      {
        name: [this.board.name, [Validators.required, Validators.minLength(3)]],
        description: [this.board.description],
        visibility: [this.board.settings.visibility],
        fbLogoVisibility: [
          (this.board.settings.fbLogoVisibility =
            this.board.settings.fbLogoVisibility || false),
        ],
        disablePost: [
          (this.board.settings.disablePost =
            this.board.settings.disablePost || false),
        ],
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit() {
    this.createForm();
  }

  async upload(event) {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    // this.ref = this.afStorage.ref(randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    //this.task = this.ref.put(event.target.files[0]);
    const picture = await this.boardService.uploadBoardPicture(
      this.board,
      event.target.files[0]
    );
    this.board.settings.logo = await picture.ref.getDownloadURL();
  }

  async save() {
    if (!this.boardForm.valid) return;

    this.board.name = this.boardForm.controls['name'].value;
    this.board.description = this.boardForm.controls['description'].value;
    this.board.settings.visibility = this.boardForm.controls[
      'visibility'
    ].value;
    this.board.settings.disablePost = this.boardForm.controls[
      'disablePost'
    ].value;
    this.board.settings.fbLogoVisibility = this.boardForm.controls[
      'fbLogoVisibility'
    ].value;

    await this.boardService.setBoard(this.board);
    this.close();
  }

  async deleteBoard() {
    let project: Project = await this.projectService.getProject(
      this.board.projectID
    );
    const projectIndex = project.boards.findIndex((b) => b === this.board.id);
    project.boards.splice(projectIndex, 1);

    let user: User = await this.userService.getUser();
    const userIndex = user.boards.findIndex((b) => b === this.board.id);
    user.boards.splice(userIndex, 1);

    for (let postId of this.board.posts) {
      const post = await this.postService.getPost(postId);
      let creator: User = await this.userService.getUserById(post.creator);
      const creatorIndex = creator.createdPosts.findIndex((b) => b === postId);
      creator.createdPosts.splice(creatorIndex, 1);

      await this.userService.setUser(creator);
      await this.postService.deletePost(postId);

      for (let upvote of user.upvotes) {
        const upvoteIndex = user.upvotes.findIndex((u) => u === upvote);
        user.upvotes.splice(upvoteIndex, 1);
      }
    }

    await this.projectService.setProject(project);
    await this.userService.setUser(user);
    await this.boardService.deleteBoard(this.board.id);
    this.router.navigate(['/board-list/' + this.board.projectID]);
  }

  close() {
    this.closeSettingsModal.emit(true);
  }
}
