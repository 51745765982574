import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-select-posts',
  templateUrl: './select-posts.component.html',
  styleUrls: ['./select-posts.component.css'],
})
export class SelectPostsComponent implements OnInit {
  @Output() closeSelectPosts = new EventEmitter<Post[]>();
  @Input() boards: Board[];
  @Input() type?: string;
  posts: Post[] = [];
  selectedPosts: Post[] = [];

  boardList: any[] = [];
  constructor(private postService: PostService) {}

  ngOnInit(): void {
    this.loadPosts();
  }

  async loadPosts() {
    this.boardList = this.boards;
    for (let board of this.boardList) {
      board.expand = true;
      this.posts = [];
      for (let postId of board.posts) {
        let post = await this.postService.getPost(postId);
        if (this.type === 'roadmap') {
          post.roadmapId = post.roadmapId || '';
          if (post.roadmapId === '') {
            this.posts.push(post);
          }
        } else {
          this.posts.push(post);
        }
      }
      board.postList = this.posts;
    }
  }

  setSelectedPosts(post: Post) {
    const isPostSelected = this.selectedPosts.some((sp) => sp.id === post.id);
    if (!isPostSelected) {
      this.selectedPosts.push(post);
    } else {
      this.selectedPosts = this.selectedPosts.filter((sp) => sp.id !== post.id);
    }
  }

  close() {
    this.closeSelectPosts.emit(this.selectedPosts);
  }
}
