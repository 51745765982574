import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Project} from '../models/project.model'

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private db: AngularFirestore,private afStorage: AngularFireStorage) {
  }

  async getProject(projectId) {
    const snapshot = await this.db.collection("projects").ref.doc(projectId).get();
    const project = snapshot.data() as Project
    return project;
  }

  async createProject(project:Project){
    const id = this.db.collection("projects").doc().ref.id
    project.id = id
    await this.db.collection("projects").doc(id).set(project);
    return id
  }

  async setProject(project: Project) {
    return this.db.collection("projects").doc(project.id).set({ ...project });
  }

  async deleteProject(projectId){
    await this.db.collection("projects").doc(projectId).delete();
  }

  uploadBoardPicture(randomId,picture) {
    return this.afStorage.upload('/picture/'+randomId, picture);  
   }
}
