<div class="flex flex-no-wrap">
  <app-sidebar [projectId]="projectId"></app-sidebar>
  <div class="py-8 px-12 w-full">
    <div class="flex justify-between">
      <div
        (click)="back()"
        class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
      >
        <svg
          class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">
          back
        </p>
      </div>
    </div>

    <div class="container mx-auto shadow bg-white dark:bg-gray-800 rounded">
      <div class="xl:w-full border-b border-gray-300 dark:border-gray-700 py-5">
        <div class="flex items-center w-11/12 mx-auto">
          <p class="text-lg text-gray-800 dark:text-gray-100 font-bold">
            Add Changelog
          </p>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div
          class="xl:w-9/12 mx-auto md:flex-row flex-col space-y-5 md:space-y-0 justify-between mt-10 flex xl:mx-0"
        >
          <div class="flex flex-col xl:w-2/6 w-full">
            <label
              for="username"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Title</label
            >
            <input
              type="text"
              id="username"
              name="username"
              [(ngModel)]="title"
              class="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800 bg-transparent dark:text-gray-100"
              placeholder="Version 2.0 is live"
              required
            />
          </div>
          <div class="flex flex-col xl:w-2/6 w-full">
            <label
              for="username"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Visibility</label
            >
            <div class="flex space-x-4">
              <p class="text-sm">{{ status ? "Live" : "Draft" }}</p>
              <div
                class="cursor-pointer w-12 h-6 rounded-full bg-indigo-700 relative shadow-sm"
              >
                <input
                  [checked]="status"
                  (change)="status = !status"
                  type="checkbox"
                  name="toggle"
                  id="toggle2"
                  class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                />
                <label
                  for="toggle2"
                  class="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                ></label>
              </div>
              <!-- Code block ends -->
              <style>
                .checkbox:checked {
                  /* Apply class right-0*/
                  right: 0;
                }
                .checkbox:checked + .toggle-label {
                  /* Apply class bg-indigo-700 */
                  background-color: #ff7070;
                }
              </style>
            </div>
          </div>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
        <div class="w-full xl:mx-0">
          <div class="mt-8 flex flex-col w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Changelog Text</label
            >

            <quill-editor
              class="w-full shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent resize-none text-gray-800 dark:text-gray-100"
              [(ngModel)]="htmlstring"
              theme="snow"
            ></quill-editor>
          </div>

          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Link Posts to this Changlog</label
            >
            <button
              (click)="openSelectPosts()"
              class="my-2 bg-white transition duration-150 ease-in-out hover:border-indigo-600 hover:text-indigo-600 rounded border border-indigo-700 text-indigo-700 px-6 py-2 text-sm"
            >
              Add Post
            </button>
          </div>
          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              *ngIf="posts.length > 0"
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Linked Posts</label
            >

            <div class="flex flex-col">
              <div
                class="ml-2 m-2 flex space-x-6 items-center"
                *ngFor="let post of posts"
              >
                <p>{{ post.title }}</p>
                <div class="ml-2 flex items-center">
                  <span class="text-xs text-gray-500 font-normal">{{
                    post.status
                  }}</span>
                </div>

                <svg
                  (click)="removePost(post)"
                  class="h-4 w-4 text-fb-red"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="mt-10 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
            <label
              for="about"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Notify Users</label
            >
            <div class="">
              <div class="flex py-6">
                <div class="h-32 mb-2">
                  <!-- Code block starts -->
                  <div class="relative">
                    <div
                      (click)="showNotificationMenu = !showNotificationMenu"
                      class="bg-white dark:bg-gray-800 flex items-center justify-between border rounded border-gray-300 dark:border-gray-700 w-40 cursor-pointer"
                      data-menu
                    >
                      <p
                        class="pl-3 py-3 text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal font-normal"
                      >
                        {{ notification.text }}
                      </p>
                      <div
                        class="cursor-pointer text-gray-600 dark:text-gray-400 mr-3"
                      >
                        <svg
                          *ngIf="showNotificationMenu"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-chevron-up"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="6 15 12 9 18 15" />
                        </svg>
                        <svg
                          *ngIf="!showNotificationMenu"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-chevron-up"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </div>
                    </div>
                    <ul
                      *ngIf="showNotificationMenu"
                      class="visible transition duration-300 opacity-100 bg-white dark:bg-gray-800 shadow rounded mt-2 w-48 py-1 absolute"
                    >
                      <li
                        (click)="setNotification('none')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        None
                      </li>
                      <li
                        (click)="setNotification('commented')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who commented on a post
                      </li>
                      <li
                        (click)="setNotification('upvoted')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who upvoted a post
                      </li>
                      <li
                        (click)="setNotification('commentedUpvoted')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        Who commented or upvoted a post
                      </li>
                      <li
                        (click)="setNotification('all')"
                        class="cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                      >
                        All registered Users
                      </li>
                    </ul>
                  </div>
                  <!-- Code block ends -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full py-4 sm:px-12 px-4 bg-gray-100 dark:bg-gray-700 mt-6 flex justify-end rounded-bl rounded-br"
      >
        <button
          class="bg-indigo-600 text-white transition duration-150 ease-in-out hover:border-indigo-600 hover:text-indigo-600 rounded border border-indigo-700 px-8 py-2 text-sm focus:outline-none"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<app-select-posts
  [boards]="boards"
  (closeSelectPosts)="closeSelectPosts($event)"
  *ngIf="showPostDialog && boards"
></app-select-posts>
