import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project-settings-tab',
  templateUrl: './project-settings-tab.component.html',
  styleUrls: ['./project-settings-tab.component.css'],
})
export class ProjectSettingsTabComponent implements OnInit {
  @Output() closeSettingsModal = new EventEmitter();
  projectForm: FormGroup;
  showDeleteBoard: boolean = false;
  projectId: string;
  project: Project;
  user: User;
  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService,
    private postService: PostService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUser();

    this.projectId = this.route.snapshot.paramMap.get('id');
    this.project = await this.projectService.getProject(this.projectId);

    this.createForm();
  }

  createForm() {
    this.project.settings = this.project.settings || {
      linkBoards: false,
      logo: '',
    };
    this.projectForm = this.fb.group(
      {
        name: [
          this.project.name,
          [Validators.required, Validators.minLength(3)],
        ],
        description: [this.project.description],
        linkBoards: [
          (this.project.settings.linkBoards =
            this.project.settings.linkBoards || false),
        ],
      },
      { updateOn: 'change' }
    );
  }

  async save() {
    if (!this.projectForm.valid) return;

    this.project.name = this.projectForm.controls['name'].value;
    this.project.description = this.projectForm.controls['description'].value;
    this.project.settings.linkBoards = this.projectForm.controls[
      'linkBoards'
    ].value;
    await this.projectService.setProject(this.project);
    this.close();
  }

  async upload(event) {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    // this.ref = this.afStorage.ref(randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    //this.task = this.ref.put(event.target.files[0]);
    const picture = await this.boardService.uploadBoardPicture(
      this.project.id,
      event.target.files[0]
    );
    this.project.settings.logo = await picture.ref.getDownloadURL();
  }

  async deleteProject() {
    for (let boardId of this.project.boards) {
      const board = await this.boardService.getBoard(boardId);

      let user: User = await this.userService.getUser();
      const userIndex = user.boards.findIndex((b) => b === boardId);
      user.boards.splice(userIndex, 1);

      const userProjectIndex = user.projects.findIndex(
        (b) => b === this.project.id
      );
      user.projects.splice(userProjectIndex, 1);

      for (let postId of board.posts) {
        const post = await this.postService.getPost(postId);
        let creator: User = await this.userService.getUserById(post.creator);
        const creatorIndex = creator.createdPosts.findIndex(
          (b) => b === postId
        );
        creator.createdPosts.splice(creatorIndex, 1);

        await this.userService.setUser(creator);
        await this.postService.deletePost(postId);

        for (let upvote of user.upvotes) {
          const upvoteIndex = user.upvotes.findIndex((u) => u === upvote);
          user.upvotes.splice(upvoteIndex, 1);
        }
      }

      await this.userService.setUser(user);
      await this.boardService.deleteBoard(board.id);
    }

    await this.projectService.deleteProject(this.project.id);

    this.router.navigate(['/project/']);
  }

  close() {
    this.closeSettingsModal.emit(true);
  }
}
