import { Pipe, PipeTransform } from '@angular/core';
import { ChangelogService } from '../services/changlog.service';

@Pipe({
  name: 'changelog',
})
export class ChangelogPipe implements PipeTransform {
  constructor(private changelogService: ChangelogService) {}

  transform(changlogId: string): Promise<string> {
    return this.changelogService.getChangelog(changlogId).then((changelog) => {
      return changelog.title;
    });
  }
}
