<div class="flex flex-no-wrap">
  <app-sidebar
    *ngIf="board"
    [projectId]="board?.projectID"
    [boards]="boards"
  ></app-sidebar>
  <div class="py-8 px-12 w-full">
    <div class="flex justify-between">
      <div
        (click)="back()"
        class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
      >
        <svg
          class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">
          back
        </p>
      </div>

      <ul
        class="flex flex-col md:flex-row md:items-center text-gray-600 text-sm"
      >
        <li class="flex items-center md:mt-0">
          <a
            *ngIf="!showDeletePost"
            (click)="showDeletePost = true"
            class="my-2 flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 text-gray-600 p-4 rounded-full py-2 text-sm cursor-pointer"
          >
            <span class="h-4 w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div
      *ngIf="showDeletePost"
      class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
    >
      <div class="flex pb-3 items-center">
        <div class="-ml-1 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-trash"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="4" y1="7" x2="20" y2="7" />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
          </svg>
        </div>
        <p class="text-lg text-gray-800 font-semibold pl-2">
          Delete this Post?
        </p>
      </div>
      <p class="text-sm text-gray-600 pb-3 font-normal">
        Do you really want to delete this post?
      </p>
      <button
        (click)="deletePost()"
        class="focus:outline-none bg-red-400 transition duration-150 ease-in-out rounded text-white px-3 py-2 text-xs"
      >
        Delete
      </button>
    </div>

    <div
      class="flex w-full justify-between items-center mb-6 md:mb-12"
      *ngIf="post"
    >
      <!-- Card is full width. Use in 12 col grid for best view. -->
      <!-- Card code block start -->
      <div
        class="flex flex-col lg:flex-row mx-auto w-full bg-white shadow rounded"
      >
        <div class="w-full p-6">
          <div class="flex flex-col md:flex-row justify-between items-center">
            <div class="">
              <!-- <h5 class="text-gray-800 font-medium text-2xl">
                {{post?.title}}
              </h5> -->
              <input
                (blur)="setPost()"
                [(ngModel)]="post.title"
                class="text-gray-800 font-medium text-2xl border border-white resize-none shadow-none overflow-auto overflow-y-hidden appearance-none focus:border-blue-500 focus:bg-white focus:border-solid focus:border-2 hover:bg-gray-200"
              />
            </div>

            <a
              (click)="upvote(post)"
              [ngClass]="{
                'bg-green-500 text-white': user?.upvotes.includes(post.id)
              }"
              class="group flex flex-col justify-center items-center mt-6 py-1 w-full sm:w-52 border rounded-md hover:bg-green-500 bg-green-50 cursor-pointer"
            >
              <svg
                class="group-hover:text-white w-6"
                [ngClass]="{
                  'text-white': user?.upvotes.includes(post.id),
                  'text-green-400': !user?.upvotes.includes(post.id)
                }"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>

              <div
                class="inline-block font-medium text-lg group-hover:text-white"
                [ngClass]="{
                  'text-white': user?.upvotes.includes(post.id),
                  'text-green-400': !user?.upvotes.includes(post.id)
                }"
              >
                {{ post?.upvotes.length }}
              </div>
            </a>
          </div>
          <div
            class="flex flex-col lg:flex-row items-start lg:items-center justify-between"
          >
            <div class="flex items-center space-x-4 mt-4">
              <div class="flex space-x-2">
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal pr-3 mr-2">
                  {{ post?.date_created.toDate() | date: "medium" }}
                </p>
              </div>
              <div class="flex space-x-2">
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal pr-3">
                  {{ post?.creator | user | async }}
                </p>
              </div>
              <div class="flex space-x-2">
                <div class="relative">
                  <div
                    class="h-5 w-auto px-2 mb-4 md:mb-0 rounded-md flex items-center justify-center"
                  >
                    <div
                      (click)="showStatusList = !showStatusList"
                      class="cursor-pointer space-x-2 flex items-center"
                    >
                      <svg
                        class="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                      <span class="text-sm text-gray-500 font-normal"
                        >Status: {{ post.status }}
                      </span>
                    </div>
                  </div>
                  <ul
                    *ngIf="showStatusList"
                    class="z-10 bg-white transition duration-300 bg-white shadow rounded mt-2 w-40 py-1 absolute"
                  >
                    <li
                      (click)="setStatus('CREATED')"
                      class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                    >
                      Created
                    </li>
                    <li
                      (click)="setStatus('IN_PROGRESS')"
                      class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                    >
                      In Progress
                    </li>
                    <li
                      (click)="setStatus('DONE')"
                      class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                    >
                      Done
                    </li>
                    <li
                      (click)="setStatus('DUPLICATE')"
                      class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                    >
                      Duplicate
                    </li>
                    <li
                      (click)="setStatus('WONT_DO')"
                      class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
                    >
                      Won't Do
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="flex space-x-2 cursor-pointer"
                *ngIf="post?.changelog"
                (click)="openChangelog()"
              >
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal pr-3">
                  {{ post?.changelog | changelog | async }}
                </p>
              </div>

              <div
                class="flex space-x-2 cursor-pointer"
                *ngIf="post?.roadmapId"
                (click)="openRoadmap()"
              >
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal pr-3">
                  {{ post?.roadmapId | roadmap | async }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-2 lg:col-span-8">
            <a
              *ngFor="let tag of post.tags"
              [ngStyle]="{
                'background-color': tag | tagColor | async
              }"
              (click)="openTag(tag)"
              class="cursor-pointer inline-block rounded-full text-white duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100"
            >
              {{ tag | tag | async }}
            </a>

            <a
              *ngIf="!showAddTag"
              (click)="showTags()"
              class="cursor-pointer inline-block rounded-full border border-gray-600 text-gray-600 duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100"
            >
              Add Tag
            </a>

            <div *ngIf="showAddTag" class="flex justify-start flex-wrap">
              <p class="text-sm text-gray-600 mr-2">Available Tags:</p>
              <div
                *ngFor="let tag of tags"
                [ngStyle]="getTagStyle(tag)"
                (click)="addTag(tag)"
                class="cursor-pointer inline-block rounded-full text-white duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100"
              >
                <span class="text-xs text-white font-normal">{{
                  tag.name
                }}</span>
              </div>
            </div>
          </div>
          <h3 class="text-lg text-gray-800 font-bold mt-5 mb-1" *ngIf="post.attachments?.length > 0">Attachment</h3>
          <div class="mb-4 flex  space-x-2 mt-2 lg:mb-0 mr-4">
            <img
            (click)="openImage(image)"
              *ngFor="let image of post.attachments"
              [src]="image"
              alt=""
              class="h-16 w-auto overflow-hidden shadow cursor-pointer"
            />
          </div>
          <h3 class="text-lg text-gray-800 font-bold mt-5 mb-1">Description</h3>
          <!-- <p class="text-gray-600 text-sm font-normal">
           {{post?.description}}
          </p> -->

          <textarea
            (keyup)="autoGrowTextZone($event)"
            rows="10"
            (blur)="setPost()"
            [(ngModel)]="post.description"
            class="text-gray-600 w-full text-sm font-normal border border-white resize-none shadow-none overflow-auto overflow-y-hidden appearance-none focus:border-blue-500 focus:bg-white focus:border-solid focus:border-2 hover:bg-gray-200"
          >
          </textarea>
          <!--   <div class="flex items-center mt-6 w-full">
      <div class="shadow border-2 border-white rounded-full w-8 h-8">
        <img
          class="w-full h-full overflow-hidden object-cover rounded-full"
          src="https://dh-ui.s3.amazonaws.com/assets/photo-1564061170517-d3907caa96ea.jfif"
          alt="avatar"
        />
      </div>
      <div class="-ml-2 shadow border-2 border-white rounded-full w-8 h-8">
        <img
          class="w-full h-full overflow-hidden object-cover rounded-full"
          src="https://dh-ui.s3.amazonaws.com/assets/photo-1548958921-c5c0fe1b307d.jfif"
          alt="avatar"
        />
      </div>
      <div class="-ml-2 shadow border-2 border-white rounded-full w-8 h-8">
        <img
          class="w-full h-full overflow-hidden object-cover rounded-full"
          src="https://dh-ui.s3.amazonaws.com/assets/photo-1566753323558-f4e0952af115.jfif"
          alt="avatar"
        />
      </div>
      <div class="-ml-2 shadow border-2 border-white rounded-full w-8 h-8">
        <img
          class="w-full h-full overflow-hidden object-cover rounded-full"
          src="https://dh-ui.s3.amazonaws.com/assets/beautiful-woman-medium-shot-with-pink-background_23-2148316670.jpg"
          alt="avatar"
        />
      </div>
      <div class="-ml-2 shadow border-2 border-white rounded-full w-8 h-8">
        <img
          class="w-full h-full overflow-hidden object-cover rounded-full"
          src="https://dh-ui.s3.amazonaws.com/assets/excited-happy-young-pretty-woman_171337-2006.jpg"
          alt="avatar"
        />
      </div>
      <p class="text-gray-600 text-sm font-normal ml-2">+2 more participants</p>
    </div> -->
        </div>
      </div>
      <!-- Card code block end -->

      <!--  <div class="rounded relative w-8/12 md:w-1/3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-500 absolute ml-4 inset-0 m-auto icon icon-tabler icon-tabler-search"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z"></path>
          <circle cx="10" cy="10" r="7"></circle>
          <line x1="21" y1="21" x2="15" y2="15"></line>
        </svg>
        <input
          class="border border-gray-100 focus:outline-none focus:border-brand rounded-full w-full text-sm bg-gray-100 text-gray-500 pl-12 sm:pl-10 md:pl-12 py-3 pr-4"
          type="text"
          placeholder="Search"
        />
      </div>
      <div class="flex items-center ml-6 md:ml-0">
        <div class="mr-2 md:mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M11.6667 5.83333C11.6667 4.54467 12.7113 3.5 14 3.5C15.2887 3.5 16.3333 4.54467 16.3333 5.83333C19.0635 7.12431 20.8584 9.81662 21 12.8333V16.3333C21.178 17.8043 22.044 19.1032 23.3333 19.8333H4.66666C5.95596 19.1032 6.82195 17.8043 7 16.3333V12.8333C7.14159 9.81662 8.93647 7.12431 11.6667 5.83333"
              stroke="#718096"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M10.5 19.8333V21C10.5 22.933 12.067 24.5 14 24.5C15.933 24.5 17.5 22.933 17.5 21V19.8333"
              stroke="#718096"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <img
          class=""
          src="https://cdn.tuk.dev/assets/components/todos/profile.png"
          alt="profile"
          srcset=""
        />
      </div>
    </div> -->
    </div>

    <div class="ml-4 mb-2 text-gray-800 font-medium text-2xl">Comments</div>
    <div class="flex w-full justify-between items-center mb-6 md:mb-12">
      <div class="w-full shadow-md rounded overflow-hidden">
        <div
          class="bg-white rounded flex w-full items-center py-5 border-b-2 border-gray-200"
        >
          <input
            type="text"
            [(ngModel)]="commentText"
            class="text-lg px-6 text-gray-500 w-full focus:outline-none"
            placeholder="Your comment"
            (keyup.enter)="addComment()"
          />
          <div
            (click)="addComment()"
            class="p-4 cursor-pointer flex items-center border-l border-gray-300 flex-col justify-center text-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-up"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
        <div class="rounded px-6 md:px-12 py-5 md:py-10 bg-gray-100">
          <div class="flex items-center" (click)="showComments = !showComments">
            <p class="text-lg font-semibold leading-loose text-gray-500">
              {{ comments.length }} Comments
            </p>
            <svg
              *ngIf="showComments"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 md:w-auto cursor-pointer text-gray-600 icon icon-tabler icon-tabler-chevron-down"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="6 9 12 15 18 9" />
            </svg>

            <svg
              *ngIf="!showComments"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 md:w-auto cursor-pointer text-gray-600 icon icon-tabler icon-tabler-chevron-down"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
          <div *ngIf="showComments">
            <div
              class="mt-7 md:mt-14 md:flex items-start"
              *ngFor="let comment of comments"
            >
              <div class="mt-5 w-full md:mt-0">
                <div class="flex w-full justify-between">
                  <p class="text-md font-semibold text-gray-800">
                    {{ comment.user | user | async }}
                    <span class="text-gray-500 font-normal"
                      >&nbsp;&nbsp;&nbsp;
                      {{ comment.date.toDate() | date: "medium" }}</span
                    >
                  </p>
                  <a
                    (click)="deleteComment(comment)"
                    class="mx-2 cursor-pointer flex items-center bg-red-300 transition duration-150 ease-in-out hover:bg-red-600 border border-red-300 rounded-full pl-3 pr-3 py-2 text-sm"
                  >
                    <span class="h-4 w-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <p class="text-md text-gray-500">{{ comment.comment }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
